import {Container} from "@mui/material";
import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "./styles.css";
import "swiper/css";
import arrow from "../../assets/images/arrow-logo.svg";
import playButton from '../../assets/images/Video Play Button.svg'
import ModalComponent from "../ModalComponent/ModalComponent";
import {useEffect} from "react";
import axiosApi from "../../axiosApi";
import {mediaUrl} from "../../config";


export const FeedbackCarousel = () => {

    const [show, setShow] = useState(false);
    const [id, setId] = useState(0);


    const [dataFeedback, setDataFeedback] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataFeedback = async () => {
            try {
                const result = await axiosApi.get('/main_page/feedback/');
                setDataFeedback(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchDataFeedback();
    }, [])


    console.log(dataFeedback)

    return (

        <div>
            <div style={{boxShadow: "8px 8px 35px 6px rgba(161,149,149,0.5)"}}></div>
            {dataFeedback.length > 0 && (
                <div className={"feedback-block"}>
                    <Container maxWidth={"lg"}>
                        <h3><img src={arrow} alt="arrow" style={{marginRight: "10px"}}/>What Our Students Are Saying
                        </h3>
                        <div className={"slider-container-video"}>

                            <div className={"swiper-container-1"}>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop
                                    breakpoints={{
                                        // when window width is >= 320px
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 10
                                        },
                                        // when window width is >= 480px
                                        480: {
                                            slidesPerView: 2,
                                            spaceBetween: 20
                                        },
                                        // when window width is >= 740px
                                        540: {
                                            slidesPerView: 2,
                                            spaceBetween: 30
                                        },
                                        740: {
                                            slidesPerView: 2,
                                            spaceBetween: 40
                                        },
                                        850: {
                                            slidesPerView: 3,
                                            spaceBetween: 40
                                        }

                                    }

                                    }
                                >
                                    {dataFeedback.map((slide) => (
                                        <SwiperSlide key={slide.id}>
                                            <div className={"feedback-slider-img-wrapper"}>
                                                <img src={mediaUrl + slide.img} alt={"slide"} className={"slide-img"}/>
                                                <div className={"feedback-box-gradient"}>
                                                    <button style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                setShow(true);
                                                                setId(slide.id)

                                                            }}>
                                                        <img src={playButton} alt="play" className={"play-img"}/>
                                                    </button>
                                                </div>
                                            </div>
                                            {show && id === slide.id && (
                                                <ModalComponent open={show} onClose={() => setShow(false)}
                                                                url={slide.l}/>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                        </div>
                    </Container>
                </div>
            )}
        </div>
    )
        ;
};
