import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";

import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import './styles.css';
import team1 from '../../assets/images/team1.jpg';
import team2 from '../../assets/images/team2.jpg';
import team3 from '../../assets/images/team3.jpg';
import arrow from '../../assets/images/arrow.png';
import arrowTitle from "../../assets/images/arrow-logo.svg";
import mailto from '../../assets/images/Email_icon.svg';
import linkedin from '../../assets/images/LinkedIn_Icon.svg';
import axiosApi from "../../axiosApi";
import {mediaUrl} from "../../config";


const TeamBlock = () => {
    // const [isFlipped, setIsFlipped] = useState(true);
    const [dataTeam, setDataTeam] = useState([]);
    const [error, setError] = useState(null);

    useEffect(()=>{
        const fetchDataTeam = async () => {
            try {
                const result = await axiosApi.get('/about_us/team/');
                setDataTeam(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchDataTeam();
    },[])



    const [flippedCards, setFlippedCards] = useState([]);

    // Helper function to flip a card
    function flipCard(index) {
        // Create a new array with the updated flip state
        const newFlippedCards = [...flippedCards];
        newFlippedCards[index] = !newFlippedCards[index];
        setFlippedCards(newFlippedCards);
    }

    // const members = [
    //     {name: "Daniel Atkinson", position: "Co-founder/Instructor", picture: team1},
    //     {name: "Aaron Grant", position: "Co-founder/Instructor", picture: team2},
    //     {name: "Jennifer Stark", position: "Project Manager", picture: team3},
    //     {name: "Daniel Atkinson", position: "Co-founder/Instructor", picture: team1},
    //     {name: "Aaron Grant", position: "Co-founder/Instructor", picture: team2},
    //     {name: "Jennifer Stark", position: "Project Manager", picture: team3},
    // ]
    return (
        <div style={{marginBottom: "50px"}}>
            <Grid container justifyContent={"space-between"} sx={{marginTop: "62px"}}>
                <Grid item>
                    <h3 className={"team-title"}>
                        <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                        Our Team
                    </h3>
                </Grid>
                <Grid item>
                    {/*<button>*/}
                    {/*    See all*/}
                    {/*</button>*/}
                </Grid>
            </Grid>
            <div className={"team-swiper-container"}>
                <Swiper
                    pagination={{
                        el: '.swiper-pagination',
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    slidesPerView={3}
                    spaceBetween={100}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }}
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        // when window width is >= 480px
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        // when window width is >= 740px
                        540: {
                            slidesPerView: 2,
                            spaceBetween: 30
                        },
                        740: {
                            slidesPerView: 3,
                            spaceBetween: 40
                        },
                        1040: {
                            slidesPerView: 3,
                            spaceBetween: 100
                        },

                    }

                    }
                >

                    {dataTeam && dataTeam.map((member, index) => (
                        <SwiperSlide key={index}>
                            <div className={"flip-card"}>
                                <div className={`flip-card-inner ${flippedCards[index] ? 'is-flipped' : ''}`}
                                     onClick={() => flipCard(index)}>
                                    <div className={"team-front"}>
                                        <div className={"team-card"}>
                                            <div>
                                                <img src={mediaUrl+member.img} alt={member.n}/>
                                            </div>
                                            <div className={"team-text"}>
                                                <p>{member.n}</p>
                                                <p><span>{member.p}</span></p>
                                                <div className={"arrow-icon-wrapper"}>
                                                    <img src={arrow} alt=""/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="team-back">

                                        <div className={"team-card"}>
                                            <div>
                                                <img src={mediaUrl+member.img} alt=""/>
                                            </div>
                                            <div className={"team-text"}>
                                                <p>{member.n}</p>
                                                <p><span>{member.p}</span></p>
                                                <div className={"arrow-icon-wrapper"}>
                                                    <img src={arrow} alt=""/>
                                                </div>
                                            </div>
                                            <div className={"back-text"}>
                                                <div className={"p-container"}>
                                                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ea*/}
                                                    {/*    magni quisquam vel?</p>*/}
                                                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias*/}
                                                    {/*    asperiores et nostrum quidem voluptates voluptatibus.</p>*/}
                                                    <p>{member.d}</p>
                                                </div>
                                                <div className={"flip-icons-box"} onClick={(event => {
                                                    event.stopPropagation()
                                                })}>
                                                    <a href={"mailto:"+member.e_l}><img src={mailto} alt="mail"/></a>
                                                    <a href={member.lin_l}><img src={linkedin} alt="mail"/></a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                    ))}


                    {/*<SwiperSlide>*/}
                    {/*    <div className={"team-card"}>*/}
                    {/*        <div>*/}
                    {/*            <img src={team2} alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className={"team-text"}>*/}
                    {/*            <p>Aaron Grant</p>*/}
                    {/*            <p><span>Co-founder/Instructor</span></p>*/}
                    {/*            <div className={"arrow-icon-wrapper"}>*/}
                    {/*                <img src={arrow} alt=""/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}


                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide>*/}
                    {/*    <div className={"team-card"}>*/}
                    {/*        <div>*/}
                    {/*            <img src={team3} alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className={"team-text"}>*/}
                    {/*            <p>Jennifer Stark</p>*/}
                    {/*            <p><span>Project Manager</span></p>*/}
                    {/*            <div className={"arrow-icon-wrapper"}>*/}
                    {/*                <img src={arrow} alt=""/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide>*/}
                    {/*    <div className={"team-card"}>*/}
                    {/*        <div>*/}
                    {/*            <img src={team2} alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className={"team-text"}>*/}
                    {/*            <p><b>Aaron Grant</b></p>*/}
                    {/*            <p><span>Co-founder/Instructor</span></p>*/}
                    {/*            <div className={"arrow-icon-wrapper"}>*/}
                    {/*                <img src={arrow} alt=""/>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}

                </Swiper>
                <div className="swiper-pagination-container">
                    <div className="swiper-pagination"></div>
                </div>
                <div className={"navigation-btns"}>
                    {/*<button className="swiper-button-prev"><ArrowBackIosIcon/></button>*/}
                    {/*<button className="swiper-button-next"><ArrowForwardIosIcon/></button>*/}

                    <button className="swiper-button-prev"></button>
                    <button className="swiper-button-next"></button>
                </div>

            </div>
        </div>
    );
};

export default TeamBlock;