import React, {useState} from 'react';
import './styles.css'
import {Container} from "@mui/material";
import arrowTitle from "../../assets/images/arrow-logo.svg";
import ModalComponent from "../ModalComponent/ModalComponent";
import {useEffect} from "react";
import axiosApi from "../../axiosApi";
import {mediaUrl} from "../../config";
import DOMPurify from "dompurify";


const Campus = () => {
    const [showCampusVideo, setShowCampusVideo] = useState(false);

    const [dataCampus, setDataCampus] = useState([]);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/about_us/campus/');
                setDataCampus(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, []);

    console.log(dataCampus)

    return (
        <div className={"campus-gradient"}>
            {dataCampus.length > 0 && (
                <Container maxWidth={'lg'}>
                    <h3 className={"team-title"}>
                        <img src={arrowTitle} alt="arrow"  style={{marginRight: "10px"}}/>
                        Our Campus
                    </h3>
                    <div className={"campus-img-wrapper"}
                         style={{
                             backgroundImage: `url(${mediaUrl+dataCampus[0].b_img})`,
                             backgroundSize: 'cover',
                         }}

                    >
                        <div className={"align-right"}>
                            <div  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(dataCampus[0].d)}}></div>
                            {/*<p>A place to learn, grow, <br/>and yes… <i>have fun</i> becoming an IT professional.*/}
                            {/*</p>*/}
                            <button className={"campus-learn-btn"} onClick={()=>setShowCampusVideo(true)}>Take a peak</button>
                        </div>
                        {/*<img src={campus} alt=""/>*/}
                    </div>
                    <ModalComponent open={showCampusVideo} onClose={()=>setShowCampusVideo(false)} url={dataCampus[0].l}/>
                </Container>
            )}
        </div>
    );
};

export default Campus;