import React, {useEffect, useState} from 'react';
import './styles.css';
import {Checkbox, Container, FormControlLabel, Grid} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ReCAPTCHA from "react-recaptcha";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import arrow from "../../assets/images/arrow-logo.svg";
import contactPic from '../../assets/images/ContactUs_Photo.png';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {main} from "../../paths";
import axiosApi from "../../axiosApi";
import * as Yup from "yup";
import {Formik, Form} from 'formik'

const ContactsBlock = () => {
    const navigate = useNavigate();
    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        phone: "",
        body: "",
        question: "",
        subscribe: true,
    })


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [verified, setVerified] = useState(false);
    const [recaptchaStatus, setRecaptchaStatus] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resultRecaptcha = await axiosApi.get('/contact/captcha/');
                setRecaptchaStatus(resultRecaptcha.data.enabled)

            } catch (err) {
                console.log(err)
            }
        };
        fetchData();

        return () => {
            setContactInfo({
                name: "",
                email: "",
                phone: "",
                body: "",
                question: "",
                subscribe: true,
            })

        }
    }, [])


    function onChange(value) {
        console.log("Captcha value:", value);
    }

    const loaded = () => {
        console.log("reacptcha loaded")
    }

    const verifyCallback = (response) => {
        if (response) {
            setVerified(true)
        }
    }


    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string().matches(/^\d{10}$/, 'Invalid phone number').required('Required'),
        body: Yup.string().required('Required'),
        question: Yup.string().required('Required')
    });


    const onSubmitHandler = async (values, {setSubmitting}) => {

        if (recaptchaStatus) {
            if (verified) {
                try {
                    await axiosApi.post('/contact/contact/', values)
                    toast.success("Successfully sent");
                    setContactInfo({
                        name: "",
                        email: "",
                        phone: "",
                        body: "",
                        question: "",
                        subscribe: true,
                    })

                    navigate(main)

                } catch (e) {
                    console.log(e)
                    toast.error("An error has occurred")
                }
            }
            setSubmitting(false)
        } else {
            try {
                await axiosApi.post('/contact/contact/', values)
                toast.success("Successfully sent");
                setContactInfo({
                    name: "",
                    email: "",
                    phone: "",
                    body: "",
                    question: "",
                    subscribe: true,
                })

                navigate(main)

            } catch (e) {
                console.log(e)
                toast.error("An error has occurred")
            }
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={contactInfo}
            onSubmit={onSubmitHandler}
            validationSchema={validationSchema}
        >
            {({handleSubmit, handleChange, values, errors, touched}) => (
                <Form onSubmit={handleSubmit}>
                    <div className={"contacts-block"}>
                        <Container maxWidth={"lg"}>
                            <h3><img src={arrow} alt="arrow" style={{marginRight: "10px"}}/>Contact us</h3>
                            <Grid container justifyContent={"space-between"}>

                                <Grid item xs={12} md={6}>
                                    <div className={"form-box"}>
                                        {/*<div className={"footer-logo"}>*/}
                                        {/*    <img style={{width: "197px", height: "auto"}}*/}
                                        {/*         src={logo} alt="codefish-logo"/>*/}
                                        {/*</div>*/}


                                        {/*<form onSubmit={submitFormHandler}>*/}
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item xs={12} sm={5.8}>
                                                <input
                                                    type={"text"}
                                                    placeholder={"Name*"}
                                                    className={
                                                        errors.name && touched.name
                                                            ? "row-input error"
                                                            : "row-input"
                                                    }
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}

                                                />
                                                {errors.name && touched.name && (
                                                    <p className={"required"}>{errors.name}</p>
                                                )}

                                            </Grid>
                                            <Grid item xs={12} sm={5.8}>
                                                <input
                                                    type={"text"}
                                                    placeholder={"Phone*"}
                                                    className={
                                                        errors.phone && touched.phone
                                                            ? "row-input error"
                                                            : "row-input"
                                                    }
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChange}

                                                />
                                                {errors.phone && touched.phone && (
                                                    <p className={"required"}>{errors.phone}</p>
                                                )}

                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item xs={12} sm={5.8}>
                                                <input
                                                    type={"text"}
                                                    placeholder={"Email*"}
                                                    className={
                                                        errors.email && touched.email
                                                            ? "row-input error"
                                                            : "row-input"
                                                    }
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}

                                                />
                                                {errors.email && touched.email && (
                                                    <p className={"required"}>{errors.email}</p>
                                                )}

                                            </Grid>
                                            <Grid item xs={12} sm={5.8}>
                                                <select
                                                    className={
                                                        errors.question && touched.question
                                                            ? "row-input select error"
                                                            : "row-input select"
                                                    }
                                                    name="question"
                                                    value={values.question}
                                                    onChange={handleChange}
                                                >
                                                    <option value="" disabled className={"option-disabled"}>Have a
                                                        question*
                                                    </option>
                                                    <option value="I have a question about a course">I have a question
                                                        about a
                                                        course
                                                    </option>
                                                    <option value="I have a question about fees">I have a question about
                                                        fees
                                                    </option>
                                                    <option value="I have a question about something else">I have a
                                                        question
                                                        about something else
                                                    </option>
                                                    <option value="I have a suggestion">I have a suggestion</option>
                                                </select>

                                                {errors.question && touched.question && (
                                                    <p className={"required"}>{errors.question}</p>
                                                )}
                                            </Grid>

                                        </Grid>
                                        <div>
                                            <textarea
                                                placeholder={"Message*"}
                                                className={
                                                    errors.body && touched.body
                                                        ? "textarea text-error"
                                                        : "textarea"
                                                }
                                                name={"body"}
                                                value={values.body}
                                                onChange={handleChange}

                                            />
                                        </div>
                                        {errors.body && touched.body && (
                                            <p className={"required"}>{errors.body}</p>
                                        )}

                                        {recaptchaStatus && (
                                            <div style={{marginBottom: "15px"}}>
                                                <ReCAPTCHA
                                                    sitekey="6LdNQ-kkAAAAAKAs8Nnww3tNiCMUV1yQcho2yj0b"
                                                    onChange={onChange}
                                                    onLoadCallback={loaded}
                                                    verifyCallback={verifyCallback}
                                                />
                                            </div>
                                        )}

                                        <div className={"checkbox-and-btn"}>
                                            <div className={"checkbox"}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={values.subscribe}
                                                            onChange={handleChange}
                                                            name="subscribe"
                                                            icon={<CheckBoxOutlineBlankIcon sx={{color: "#00adef"}}/>}
                                                            checkedIcon={<CheckBoxIcon sx={{color: "#00adef"}}/>}
                                                        />
                                                    }
                                                    label={<p className={"checkbox-label"}>Subscribe to our
                                                        newsletter</p>}
                                                />

                                            </div>

                                            <button className={"send-btn"} type={"submit"}
                                                    disabled={
                                                        values.name === '' &&
                                                        values.phone === '' &&
                                                        values.email === '' &&
                                                        values.body === ''
                                                    }
                                            >

                                                    <span className={"submit-span"}><MailOutlineIcon
                                                        sx={{color: "#00ADEF"}}/></span>
                                                <span className={"submit-p"}>Submit</span>
                                            </button>

                                        </div>
                                        <p className={"bottom-text"}><b>We won’t spam your inbox or ever sell your
                                            data.</b> Our
                                            monthly newsletter
                                            covers our upcoming bootcamps, upcoming events, helpful tools and tips</p>
                                        {/*</form>*/}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <div className={"contacts-image-wrapper"}>
                                        <img src={contactPic} alt=""/>
                                    </div>
                                </Grid>

                            </Grid>

                        </Container>

                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ContactsBlock;