export const main = '/';
export const about = '/about'
export const faq = '/faq'
export const programs = '/programs/:id'
export const contacts = '/contacts'
export const apply = '/apply'
export const blog = '/blog'






