import React, {useEffect, useState} from 'react';
import './styles.css'
import {Container, Grid} from "@mui/material";
import {styled} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import arrowTitle from "../../assets/images/arrow-logo.svg";
import {contacts} from "../../paths";
import {useNavigate} from "react-router-dom";
import axiosApi from "../../axiosApi";
import DOMPurify from "dompurify";
import {mediaUrl} from "../../config";
import ReactGA from 'react-ga';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    borderLeft: "none",
    borderRight: "none"
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgb(255, 255, 255)'
            : 'rgb(255, 255, 255)',
    flexDirection: 'row',
    padding:"10px 0",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const Faq = () => {
    const navigate = useNavigate();

    const [expanded, setExpanded] = React.useState('panel1');
    const [clickedDiv, setClickedDiv] = useState("1");

    const [dataFaq, setDataFaq] = useState([]);
    const [error, setError] = useState(null);

    const [questions, setQuestions] = useState([])
    const [categories,setCategories] = useState([])

    ReactGA.initialize('G-BRZWJF3QP0');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/faq/header/');
                const categories = await axiosApi.get('/faq/categories/');

                setDataFaq(result.data);
                setCategories(categories.data);

            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/faq/qanda/?tp='+clickedDiv);
                setQuestions(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, [clickedDiv]);


    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    const onDivClick = (e) => {
        setClickedDiv(e.target.id)
    }

    return (
        <div className={"faq-block"}>
            <Container maxWidth={"lg"}>
                {dataFaq.length > 0 && (
                    <Grid container justifyContent={"space-between"} sx={{paddingBottom: "20px"}}>
                        <Grid item sm={12} md={6}>
                            <div className={"faq-block-main"}>
                                <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                                <h3> {dataFaq[0].t}</h3>
                            </div>
                            <div className={"faq-block-text"}
                                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(dataFaq[0].d)}}
                            ></div>

                        </Grid>
                        <Grid item sm={12} md={5.5}>
                            <div className={"faq-img-wrapper"}>
                                <img src={mediaUrl+dataFaq[0].img} alt="faq"/>
                            </div>
                        </Grid>
                    </Grid>
                )}

                <div className={"accordion-parent"}>
                    <div className={"questions-block"}>

                        <Grid container spacing={2}>
                            {categories.length >0 &&  categories.map(cat=>(

                                <Grid item xs={12} sm={6} md={4}>
                                    <button
                                        className={clickedDiv === cat.id.toString() ? "questions-button active" : "questions-button"}
                                        id={cat.id.toString()}
                                        onClick={(event => onDivClick(event))}
                                    >

                                        {clickedDiv === cat.id.toString()? (
                                            <img src={mediaUrl+cat.i_selected} alt={cat.t} />
                                        ) : (
                                            <img src={mediaUrl+cat.i} alt={cat.t} />
                                        )}
                                        {cat.t}
                                    </button>
                                </Grid>
                                ))}
                        </Grid>
                    </div>

                    <div className={"accordion-child"}>
                        {questions.length > 0 ? questions.map((question, i) => (
                            <Accordion expanded={expanded === 'panel'+i+1} onChange={handleChange('panel'+i+1)}>
                                <AccordionSummary
                                    aria-controls="panel1d-content" id="panel1d-header"
                                    expandIcon={expanded === 'panel'+i+1
                                        ? <RemoveIcon sx={{fontSize: '16px', color: "#00ADEF"}}/>
                                        : <AddIcon sx={{fontSize: '16px', color: "#000000"}}/>}>
                                    <p className={expanded === 'panel'+i+1 ? "questions-title question-expanded" : "questions-title question-closed"}>
                                        {question.q}</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{
                                        fontSize: "14px",
                                        color: "#434040",
                                        fontWeight: "400",
                                        fontFamily: "Objektiv-Mk2, sans-serif"
                                    }}>
                                        {question.a}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )):(
                            <h3 style={{
                                fontSize: "25px",
                                color: "#00ADEF",
                                fontWeight: "400",
                                fontFamily: "Objektiv-Mk2, sans-serif",
                                textAlign:"center",
                                margin:"20px 0"
                            }}>Not found</h3>
                        )}
                    </div>

                    <div className={"ask-us"}>
                        <p>Don't see your question?</p>
                        <button onClick={()=>navigate(contacts)}>Ask us anything</button>
                    </div>
                </div>

            </Container>
        </div>
    );
};

export default Faq;