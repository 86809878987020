import React from 'react';
import {Backdrop, Card, Fade, Modal} from "@mui/material";
import "../ModalComponent/styles.css"
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Apply from "../../containers/Apply/Apply";
import './styles.css'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:"98%", md: "85%", lg:"65%"},
    backgroundColor: 'background.paper',
    p: 2,
    height: "100%",
    overflowY:"auto",
    marginTop: {xs:"50px", md: "30px", lg:"10px"},


}

const ModalComponentApply = ({open, onClose}) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Card sx={style} variant="outlined" className={"card-Modal"}>
                    <div style={{display:"flex", justifyContent:"right", marginBottom:"-10px", marginTop:"-5px"}}>
                        <IconButton onClick={onClose}><CloseIcon sx={{color:"#00ADEF"}}/></IconButton>
                    </div>
                    <Apply/>
                </Card>
            </Fade>
        </Modal>
    );
};

export default ModalComponentApply;