import React from 'react';
import ReactPlayer from 'react-player/youtube';
import './player.css';

const Player = ({url}) => {

    const config = {
        youtube: {
            playerVars: {
                modestbranding: 1,
                showinfo: 0
            }
        }
    }
    return (
        <div className="player-wrapper">
            <ReactPlayer
                url={url}
                className="react-player"
                width={"100%"}
                controls={false}
                config={config}
            />
        </div>
    );
};

export default Player;