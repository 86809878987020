import React, {useEffect, useState} from 'react';
import './styles.css'
import {Container, Grid} from "@mui/material";
import arrow from '../../assets/images/arrow.png'
import axiosApi from "../../axiosApi";
import {mediaUrl} from "../../config";

const OfferBlock = () => {

    const [error, setError] = useState(null);
    const [dataOffer, setDataOffer] = useState([]);

    useEffect(()=>{
        const fetchDataOffer = async () => {
            try {
                const result = await axiosApi.get('/main_page/offer/');
                setDataOffer(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchDataOffer();
    },[])


    return (
        <Container maxWidth={"lg"}>
            {dataOffer.length > 0 && (
                <Grid container className={"offer-block"} direction={{xs: "column"}}
                      alignItems={{md: "space-between", xs: "center"}}>
                    <Grid item
                          width={{xs: "100%"}}
                          className="offer-title-block"
                          container
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={{xs: "center", md: 'flex-start'}}
                    >
                        <h2><img src={arrow} alt="arrow" style={{transform: "scaleX(-1)", marginRight: "10px"}}/> An Agile
                            Bootcamp for Agile Students</h2>
                    </Grid>
                    <Grid item container width="100%" justifyContent={"space-between"}>


                        {dataOffer.map((offer,i) =>(
                            <div className={"card"}>
                                <div className="image-wrapper-offer">
                                    <img src={mediaUrl+offer.ic} alt={"pic1"}/>
                                </div>
                                <h3>{offer.t}</h3>
                                <div>
                                    <p>{offer.d}</p>
                                </div>
                                {/*<div className='btns-parent'>*/}
                                {/*    <button className="more-btn"> More</button>*/}
                                {/*    <button className="icon-btn"><ArrowOutwardIcon sx={{color: "#00ADEF", fontSize: "17px"}}/>*/}
                                {/*    </button>*/}
                                {/*</div>*/}

                            </div>
                        ))}

                    </Grid>

                </Grid>
            )}

        </Container>
    );
};

export default OfferBlock;