import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import {FaLayerGroup, FaRegCalendarAlt} from 'react-icons/fa'
import arrow from "../../assets/images/arrow-logo.svg";
import {useNavigate} from "react-router-dom";
import axiosApi from "../../axiosApi";
import DOMPurify from "dompurify";
import {mediaUrl} from "../../config";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import './styles.css'
import {apply} from "../../paths";
import ModalComponentApply from "../ModalComponentApply/ModalComponentApply";

const ProgramsBlock = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [applyModalShow, setApplyModalShow] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/main_page/programs/');
                setData(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, []);


    return (
    <Container maxWidth={"lg"}>
        <div className={"programs-block"}>
            <h3> <img src={arrow} alt="arrow" style={{marginRight: "10px"}}/> Our Programs</h3>
            {/*<Grid container spacing={2}>*/}

                <div className={"program-swiper-container"}>
                    <Swiper
                        modules={[ Navigation]}
                        className="mySwiper"
                        slidesPerView={2}
                        spaceBetween={80}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev"
                        }}
                        breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 20
                                },
                                // when window width is >= 740px
                                540: {
                                    slidesPerView: 1,
                                    spaceBetween: 30
                                },
                                740: {
                                    slidesPerView: 2,
                                    spaceBetween: 40
                                },
                                1040: {
                                    slidesPerView: 2,
                                    spaceBetween: 80
                                },

                            }}
                >

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {data && data.map(program=>(
                                <SwiperSlide key={program.id}>
                                    <div style={{display:"flex", flexDirection:"column",  height:"100%"}}>
                                        <div className='image-wrapper'>
                                            <img src={mediaUrl+program.img_mp} alt="student1" className={"student-pic"}/>
                                            {/*<div className={'popular'}><span>Popular</span></div>*/}
                                            {/*<div className={'rating'}>*/}
                                            {/*    <StarIcon sx={{color: "yellow", fontSize: "16px"}}/>*/}
                                            {/*    <span>4.9</span>*/}
                                            {/*</div>*/}

                                        </div>
                                        <div className="content">
                                            <h5>{program.t3_mp}</h5>

                                            <div className={'program-main-page'}
                                                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(program.sd)}}
                                            >
                                            </div>

                                        </div>
                                        <div className={"content2"}>
                                            <div className="details">
                                                {/*<p><AiOutlineClockCircle style={{color: "#40BB15", marginRight: "5px"}}/> 300+ hours</p>*/}
                                                <p><FaRegCalendarAlt style={{color: "#40BB15", marginRight: "5px"}}/> {program.dt}</p>
                                            </div>
                                            <Grid container>
                                                <Grid item >
                                                    <button className={"learn-btn"} onClick={()=>navigate('/programs/'+program.id)}>
                                                        <span><FaLayerGroup sx={{color:"white"}}/></span>
                                                        <p>Learn more</p>
                                                    </button>
                                                </Grid>
                                                <Grid>
                                                    <button className={"learn-btn"} onClick={()=>setApplyModalShow(true)}>
                                                        <span><FaLayerGroup sx={{color:"white"}}/></span>
                                                        <p>Apply now</p>
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}

                        </div>

                        {applyModalShow && (
                            <ModalComponentApply open={applyModalShow} onClose={()=>setApplyModalShow(false)}/>
                        )}

                        <div className="swiper-pagination-container">
                            <div className="swiper-pagination"></div>
                        </div>
                            <div className={data && data.length <= 2 ? "hidden":"navigation-btns-p"}>
                                <button className="swiper-button-prev"></button>
                                <button className="swiper-button-next"></button>
                            </div>

                    </Swiper>

                </div>


            {/*</Grid>*/}
        </div>
        <div style={{visibility:"hidden"}}><a id="href2" target="_blank" href="http://www.2findlocal.com/b/14759610">
            <img className="anchorImage" alt="codefish"
                 src="http://www.2findlocal.com/Set/Icons/2.png"/></a>
            <a target="_blank" href="http://taxihowmuch.com"><img alt="taxi fare calculator"
                                                                  src="http://www.2findlocal.com/Set/Icons/u.png"/></a><a
                id="href3" target="_blank"
                href="https://favecentral.com"><img alt="coupons"
                                                    src="http://www.2findlocal.com/Set/Icons/f.png"/></a>
        </div>
    </Container>
    );
};

export default ProgramsBlock;