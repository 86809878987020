import './App.css';
import Home from "./containers/Home/Home";
import {Route, Routes} from "react-router-dom";
import {about, apply, blog, contacts, faq, main, programs} from "./paths";
import Layout from "./components/Layout/Layout";
import About from "./containers/About/About";
import FAQ from "./containers/FAQ/FAQ";
import Programs from "./containers/Programs/Programs";
import Contacts from "./containers/Contacts/Contacts";
import BlogIframe from "./components/BlogIframe/BlogIframe";
import ApplyPage from "./containers/ApplyPage/ApplyPage";

function App() {
    return (
        <Layout>
            <Routes>
                <Route path={main} element={<Home/>}/>
                <Route path={about} element={<About/>}/>
                <Route path={faq} element={<FAQ/>}/>
                <Route path={programs} element={<Programs/>}/>
                <Route path={contacts} element={<Contacts/>}/>
                <Route path={apply} element={<ApplyPage/>}/>
                <Route path={blog} element={<BlogIframe/>}/>
            </Routes>
        </Layout>
    );
}

export default App;
