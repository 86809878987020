import React, {useEffect} from 'react';
import './styles.css';
import {Container, Grid} from "@mui/material";
import axiosApi from "../../axiosApi";
import {useState} from "react";

const StatisticBlock = () => {

    const [error, setError] = useState(null);
    const [dataStat, setDataStat] = useState([]);

    useEffect(()=>{
        const fetchDataStat = async () => {
            try {
                const result = await axiosApi.get('/main_page/stat/');
                setDataStat(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchDataStat();
    },[])

    return (
        <div className={"gradient-block"}>
            {dataStat.length >0 && (
                <Container maxWidth={"lg"}>
                    <Grid container justifyContent={{xs:"center", md:"space-between"}}>
                        {dataStat.map((stat,i)=>(
                            <Grid item xs={4} sm={4} md={2.3}>
                                <div className={`box box-${i+1}`}>
                                    <p className="number">{stat.v}</p>
                                    <p className={"description"}>{stat.d}</p>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            )}
        </div>
    );
};

export default StatisticBlock;