import React from 'react';
import ContactsBlock from "../../components/ContactsBlock/ContactsBlock";
import ReactGA from 'react-ga';
import {useEffect} from "react";

const Contacts = () => {
    ReactGA.initialize('G-BRZWJF3QP0');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div style={{padding:"50px 0"}}>
            <ContactsBlock/>
        </div>
    );
};

export default Contacts;