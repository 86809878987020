import React, {useEffect} from 'react';
import './styles.css'
import StatisticBlock from "../../components/StatisticBlock/StatisticBlock";
import OfferBlock from "../../components/OfferBlock/OfferBlock";
import BootcampBlock from "../../components/BootcampBlock/BootcampBlock";
import MainBlock from "../../components/MainBlock/MainBlock";
import ProgramsBlock from "../../components/ProgramsBlock/ProgramsBlock";
import {FeedbackCarousel} from "../../components/FeedbackCarousel/FeedbackCarousel";
import ReactGA from 'react-ga';


const Home = () => {
    ReactGA.initialize('G-BRZWJF3QP0');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
<>
    <MainBlock/>
    <StatisticBlock/>
    <OfferBlock/>
    <BootcampBlock/>
    <ProgramsBlock/>
  <FeedbackCarousel/>

</>


    );
};

export default Home;