import React, {useEffect, useMemo, useState} from 'react';
import {Container, Grid} from "@mui/material";
import arrowTitle from "../../assets/images/arrow-logo.svg";
import {Formik} from 'formik'
import './styles.css'
import step1 from '../../assets/images/Step1_bar.svg';
import step2 from '../../assets/images/Step2_bar.svg';
import step3 from '../../assets/images/Step3_bar.svg';
import step4 from '../../assets/images/Step4_bar.svg';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import facebook from '../../assets/images/fb.svg';
import twitter from '../../assets/images/tw.svg';
import instagram from '../../assets/images/ig.svg';
import linkedin from '../../assets/images/li.svg';
import telegram from '../../assets/images/tg.svg';
import axiosApi from "../../axiosApi";
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import {useRef} from 'react';
import ReactGA from 'react-ga';


export default function Apply() {


    const [data, setData] = useState({
        name: "",
        last_name: "",
        email: "",
        phone: "",
        course: "",
        startDate: 0,
        option: 1,
        referal: "",
        promoCode: "",
        pricingPlan: 0,
        sourceFrom: "",
    })
    const [currentStep, setCurrentStep] = useState(0);
    const [submitError, setSubmitError] = useState('');
    const [singleCourse, setSingleCourse] = useState({})


    const [courses, setCourses] = useState([]);
    const [error, setError] = useState(null);


    const [errorVerify, setErrorVerify] = useState(false);
    const [dateDisable, setDateDisable] = useState(true);

    const [recaptchaStatus, setRecaptchaStatus] = useState(false);

    ReactGA.initialize('G-BRZWJF3QP0');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/programs/dropdown/programs/');
                setCourses(result.data);
                const resultRecaptcha = await axiosApi.get('/contact/captcha/');
                setRecaptchaStatus(resultRecaptcha.data.enabled)

            } catch (err) {
                setError(err);
            }
        };
        fetchData();

        window.scrollTo(0, 0);

    }, []);


    useEffect(() => {
        const fetchData = async () => {
            if (data.course !== "") {
                try {
                    const result = await axiosApi.get('/programs/programs/' + data.course + '/');
                    setSingleCourse(result.data);
                    setDateDisable(false)
                } catch (err) {
                    setError(err);
                }
            }
        };
        fetchData();

    }, [data.course]);

    // function onChangeRecaptcha(value) {
    //     console.log("Captcha value:", value);
    //     if(value){
    //         setVerified(true)
    //     }
    // }
    //
    //
    // const verifyCallback = (response) => {
    //     if (response) {
    //         console.log("response", response)
    //         setVerified(true)
    //     }
    // }


    const onChange = (e) => {
        const value = isNaN(e.target.value) ? "" : parseInt(e.target.value, 10);
        setData(prevState => ({
            ...prevState,
            [e.target.name]: value
        }))
    }

    const handleNext = (newData) => {
        setData(prevState => ({
            ...prevState,
            ...newData,
        }))

        setCurrentStep(prevState => prevState + 1);
    }

    const handlePrev = (newData) => {
        setData(prevState => ({
            ...prevState,
            ...newData,
        }))
        setCurrentStep(prevState => prevState - 1);
    }


    const changeToggleOption = (e, value) => {
        e.preventDefault();
        setData(prevState => ({
                ...prevState,
                option: value
            })
        )
    }


    const changeTogglePricing = (number) => {
        const value = isNaN(number) ? "" : parseInt(number, 10);
        setData(prevState => ({
                ...prevState,
                pricingPlan: value
            })
        )
    }


    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        if (data.option === 0 && !isEmpty(singleCourse)) {
            singleCourse.ci_id_p && singleCourse.ci_id_p.forEach((payment) => {
                if (payment.fmt === false && payment.ot === 'Standard Plan') {
                    setData(prevState => ({
                        ...prevState,
                        pricingPlan: payment.id
                    }))
                }
            })
        } else if (data.option === 1 && !isEmpty(singleCourse)) {
            singleCourse.ci_id_p && singleCourse.ci_id_p.forEach((payment) => {
                if (payment.fmt === true && payment.ot === 'Standard Plan') {
                    setData(prevState => ({
                        ...prevState,
                        pricingPlan: payment.id
                    }))
                }
            })
        }
    }, [singleCourse, data.option])


    const StepOne = (props) => {
        const handleSubmit = (values) => {
            props.onNext(values);
        }


        const validationSchema = Yup.object().shape({
            name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            phone: Yup.string().matches(/^\d{10}$/, 'Invalid phone number').required('Required'),
        });


        return (
            <Formik
                initialValues={props.data}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({handleSubmit, handleChange, values, errors, touched}) => (
                    <form onSubmit={handleSubmit}>
                        <img src={step1} alt="step1" className={"step-img"}/>
                        <Container maxWidth={"xs"}>
                            <Grid container sx={{marginBottom: "50px", minHeight: "500px"}} flexDirection={'column'}>
                                <h2 className={"apply-modal-title"}>Contact Information</h2>
                                <input
                                    type={"text"}
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    className={errors.name && touched.name ? "apply-modal-input text-error" : "apply-modal-input"}
                                    placeholder={"First Name*"}
                                />
                                {errors.name && touched.name ? (
                                    <p className="required">{errors.name}</p>
                                ) : null}


                                <input
                                    type={"text"}
                                    name="last_name"
                                    onChange={handleChange}
                                    value={values.last_name}
                                    className={errors.last_name && touched.last_name ? "apply-modal-input text-error" : "apply-modal-input"}
                                    placeholder={"Last Name*"}
                                />
                                {errors.last_name && touched.last_name ? (
                                    <p className="required">{errors.last_name}</p>
                                ) : null}

                                <input type={"text"}
                                       name="email"
                                       onChange={handleChange}
                                       value={values.email}
                                       className={errors.email && touched.email ? "apply-modal-input text-error" : "apply-modal-input"}
                                       placeholder={"Email*"}
                                />
                                {errors.email && touched.email ? (
                                    <p className="required">{errors.email}</p>
                                ) : null}

                                <input type={"text"}
                                       name="phone"
                                       onChange={handleChange}
                                       value={values.phone}
                                       className={errors.phone && touched.phone ? "apply-modal-input text-error" : "apply-modal-input"}
                                       placeholder={"Phone*"}
                                />
                                {errors.phone && touched.phone ? (
                                    <p className="required">{errors.phone}</p>
                                ) : null}

                                {/*<div style={{*/}
                                {/*    alignSelf:"flex-end",*/}
                                {/*    display: "flex",*/}
                                {/*    justifyContent: "space-between",*/}
                                {/*    alignItems: "center",*/}
                                {/*    marginTop: "25px",*/}

                                {/*}}>*/}

                                <Grid item container alignSelf={'flex-end'} justifyContent={"space-between"}
                                      sx={{marginTop: "auto"}}>
                                    <button type="button" className={"nav-btns hidden"}> &lt; prev</button>

                                    <div style={{display: 'flex', alignContent: "center"}}>

                                        <button type="submit" className={"nav-btns"}>Next:</button>
                                        <button className={"next-btn-title"}>

                                 <span className={"next-btn-span"}><KeyboardDoubleArrowRightIcon
                                     sx={{color: "#00ADEF"}}/></span>
                                            <span className={"next-btn-p"}> Choose your camp</span>

                                        </button>
                                    </div>
                                </Grid>

                                {/*</div>*/}

                            </Grid>

                        </Container>
                    </form>
                )}
            </Formik>
        )

    }


    const select = useMemo(() => {
        const res = () => {
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <select
                        className={"apply-modal-input select-apply "}
                        name="course"
                        value={data.course}
                        onChange={onChange}
                    >
                        <option value="" disabled className={"option-disabled"}>Select Your Course*</option>

                        {courses && courses.map(course => (
                            <option key={course.id} value={parseInt(course.id)}
                            >{course.t1}</option>
                        ))}
                    </select>

                    <select
                        className={"apply-modal-input select-apply"}
                        name="startDate"
                        value={data.startDate}
                        onChange={onChange}
                        disabled={dateDisable}
                    >
                        <option value={0} disabled className={"option-disabled"}>Select Start Date*</option>
                        {singleCourse && singleCourse.ci_id_sd && singleCourse.ci_id_sd.map(date => (
                            <option key={date.id} value={parseInt(date.id)}
                            >{date.t}</option>
                        ))}
                    </select>

                </div>
            )
        }
        return res();
    }, [data, singleCourse, dateDisable])


    const StepTwo = (props) => {
        const handleSubmit = (values) => {
            props.onNext(values);
        }

        const validationSchemaCourse = Yup.object().shape({
            course: Yup.number().required('Please select a course'),
            startDate: Yup.number().required('Please select a start date').min(1, "Please select a start date"),
        });

        return (
            <Formik
                initialValues={props.data}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validationSchema={validationSchemaCourse}
            >
                {({values, handleSubmit, errors, touched}) => {

                    return (
                        <form onSubmit={handleSubmit}>
                            <img src={step2} alt="step2" className={"step-img"}/>
                            <Container maxWidth={"xs"}>
                                <Grid
                                    container
                                    sx={{marginBottom: "50px", minHeight: "500px"}}
                                    flexDirection={"column"}
                                >
                                    <h2 className={"apply-modal-title"}>Course Selection</h2>
                                    {select}

                                    {/*{ errors.course &&  <div><p className={'step-two-error'}>Please, Select the course and the start date!</p></div>}*/}
                                    {errors.course && touched.course &&
                                        <div><p className={'step-two-error'}>{errors.course}</p></div>}
                                    {errors.startDate && touched.startDate &&
                                        <div><p className={'step-two-error'}>{errors.startDate}</p></div>}

                                    <p className={"environment"}>Learning Environment:</p>


                                    {singleCourse && singleCourse.ci_id_p && singleCourse.ci_id_p.length <= 2 && singleCourse.ci_id_p[0].fmt === true ? (
                                        <Grid
                                            container
                                            sx={{margin: "10px 0 25px"}}
                                            justifyContent={{xs: "center", sm: "space-between"}}
                                        >
                                            <Grid item xs={12}>
                                                <button
                                                    className={
                                                        data.option === 1
                                                            ? "option-toggle-btn apply-online apply-online-active "
                                                            : "option-toggle-btn apply-online"
                                                    }
                                                    onClick={(e) => changeToggleOption(e, 1)}
                                                    type={"button"}
                                                >
                                                    <span>Online</span>
                                                </button>
                                            </Grid>
                                        </Grid>
                                    ):(
                                        <Grid
                                            container
                                            sx={{margin: "10px 0 25px"}}
                                            justifyContent={{xs: "center", sm: "space-between"}}
                                        >
                                            <Grid item xs={6} sm={6}>
                                                <button
                                                    className={
                                                        data.option === 0
                                                            ? "option-toggle-btn apply-campus apply-campus-active"
                                                            : "option-toggle-btn apply-campus"
                                                    }
                                                    onClick={(e) => changeToggleOption(e, 0)}
                                                    type={"button"}
                                                >
                                                    <span>On campus</span>
                                                </button>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <button
                                                    className={
                                                        data.option === 1
                                                            ? "option-toggle-btn apply-online apply-online-active "
                                                            : "option-toggle-btn apply-online"
                                                    }
                                                    onClick={(e) => changeToggleOption(e, 1)}
                                                    type={"button"}
                                                >
                                                    <span>Online</span>
                                                </button>
                                            </Grid>
                                        </Grid>
                                    )}


                                    <Grid
                                        item
                                        container
                                        alignSelf={"flex-end"}
                                        justifyContent={"space-between"}
                                        sx={{marginTop: "auto"}}
                                    >
                                        <button
                                            type="button"
                                            className={"nav-btns"}
                                            onClick={() => props.onBack(values)}
                                        >
                                            {"<"} prev
                                        </button>

                                        <div style={{display: "flex", alignContent: "center"}}>
                                            <button type="submit" className={"nav-btns"}>
                                                Next:
                                            </button>
                                            <button className={"next-btn-title"}>
                  <span className={"next-btn-span"}>
                    <KeyboardDoubleArrowRightIcon sx={{color: "#00ADEF"}}/>
                  </span>
                                                <span className={"next-btn-p"}> Referral/Promos</span>
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </form>
                    )
                }}
            </Formik>
        );
    }


    const StepThree = (props) => {

        const handleSubmit = (values) => {
            props.onNext(values);
        }


        const validationSchemaThree = Yup.object().shape({
            sourceFrom: Yup.number().required('Required'),
        });

        return (
            <Formik
                initialValues={props.data}
                onSubmit={handleSubmit}
                validationSchema={validationSchemaThree}
            >
                {({handleSubmit, setFieldValue, values, errors, touched}) => (
                    <form onSubmit={handleSubmit}>
                        <img src={step3} alt="step3" className={"step-img"}/>
                        <Container maxWidth={"xs"}>
                            <Grid container sx={{marginBottom: "50px", minHeight: "500px"}} flexDirection={'column'}>
                                <h2 className={"apply-modal-title"}>Referrals/Promotional Offers</h2>

                                <select
                                    className={`apply-modal-input select-apply ${errors.sourceFrom && touched.sourceFrom ? 'text-error' : ''}`}
                                    name="sourceFrom"
                                    value={values.sourceFrom}
                                    onChange={e => {
                                        const value = isNaN(e.target.value) ? "" : parseInt(e.target.value, 10);
                                        setFieldValue("sourceFrom", value);
                                    }}
                                >
                                    <option value="" disabled className={"option-disabled"}>How did you hear about
                                        CodeFish?*
                                    </option>
                                    <option value={1}>Advertisement</option>
                                    <option value={2}>Email</option>
                                    <option value={3}>Facebook</option>
                                    <option value={4}>Instagram</option>
                                    <option value={5}>Reddit</option>
                                    <option value={6}>Referral</option>
                                    <option value={7}>Telegram</option>
                                    <option value={8}>TikTok</option>
                                    <option value={9}>Twitter</option>
                                    <option value={10}>Google</option>
                                    <option value={11}>Other</option>
                                </select>


                                {errors.sourceFrom && (
                                    <p className="required">{errors.sourceFrom}</p>
                                )}

                                {values.sourceFrom === 6 && (
                                    <>
                                        <input
                                            type={"text"}
                                            name="referral"
                                            onChange={e => {
                                                setFieldValue("referal", e.target.value);
                                            }}
                                            value={values.referal}
                                            className={"apply-modal-input"}
                                            placeholder={"Name of referral (optional)"}
                                        />

                                        <p className={"referral-p"}>Please enter the <b>first and last name</b> of who
                                            referred
                                            you to CodFish. Without a full name we cannot
                                            extend any referral bonuses to them. Thanks!</p>

                                    </>

                                )}

                                <input type={"text"}
                                       name="promoCode"
                                       onChange={e => {
                                           setFieldValue("promoCode", e.target.value);
                                       }}
                                       value={values.promoCode}
                                       className={"apply-modal-input"}
                                       placeholder={"Promo Code (optional)"}
                                />


                                <Grid item container alignSelf={'flex-end'} justifyContent={"space-between"}
                                      sx={{marginTop: "auto"}}>

                                    <button type="button" className={"nav-btns"}
                                            onClick={() => props.onBack(values)}> &lt; prev
                                    </button>

                                    <div style={{display: 'flex', alignContent: "center"}}>
                                        <button type="submit" className={"nav-btns"}>Next:</button>
                                        <button type={"submit"} className={"next-btn-title"}>
                                 <span className={"next-btn-span"}><KeyboardDoubleArrowRightIcon
                                     sx={{color: "#00ADEF"}}/></span>
                                            <span className={"next-btn-p"}> Payment</span>
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>
                )}
            </Formik>
        )

    }


    const StepFour = (props) => {

        const [isVerified, setIsVerified] = useState(false);
        const recaptchaRef = useRef(null);

        const onChangeRecaptcha = () => {
            setIsVerified(true);
        };

        const verifyCallback = () => {
            setIsVerified(true);
        };


        const handleSubmit = (values) => {
            if (recaptchaStatus === true) {
                if (isVerified) {
                    axiosApi.post('/contact/bio/', values)
                        .then(() => {
                            // handle the response data
                            props.onNext(values);
                        })
                        .catch(error => {
                            setSubmitError(error)
                        });
                } else {
                    setErrorVerify(true)
                }
            } else {
                axiosApi.post('/contact/bio/', values)
                    .then(() => {
                        // handle the response data
                        props.onNext(values);
                    })
                    .catch(error => {
                        setSubmitError(error)
                    });
            }
        }

        return (
            <Formik
                initialValues={props.data}
                onSubmit={handleSubmit}
            >
                {({handleSubmit, handleChange, values}) => (
                    <form onSubmit={handleSubmit}>
                        <img src={step4} alt="step4" className={"step-img"}/>
                        <Container maxWidth={"sm"}>
                            <Grid container sx={{marginBottom: "50px", minHeight: "520px"}} flexDirection={'column'}>
                                <h2 className={"apply-modal-title"}>Payment Plan Selection</h2>

                                {values.option === 1 ? (
                                    <Grid container justifyContent={"space-between"}>

                                        {singleCourse && singleCourse.ci_id_p && singleCourse.ci_id_p.map((payment) => {

                                            if (payment.fmt === true && payment.ot === 'Standard Plan') {
                                                const number = payment.od1_p.split(",");
                                                return (
                                                    <Grid item xs={12} sm={5.8} md={5.8}
                                                          sx={{
                                                              padding: "10px 1px 18px 1px",
                                                              boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                          }}>

                                                        <div style={{padding: "0  0  0 20px",}}>
                                                            <div className={"option-icon-box"}>
                                                                <p className={"option-number"}>{payment.ot}</p>
                                                                <p className={"option-p"}>{payment.fmt === true ? "Online" : "On Campus"}</p>

                                                            </div>
                                                            <div className={"installments"}>
                                                                <p className={"installment-title"}>{payment.od1_t}</p>
                                                                <p className={"installment-mode"}>{payment.od1_d}</p>

                                                                <p style={{
                                                                    fontSize: "18px",
                                                                    fontWeight: "500",
                                                                    marginBottom: "-5px"
                                                                }}>${number[0]}
                                                                    <span
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400"
                                                                        }}> per month</span>
                                                                </p>
                                                                <p style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "400"
                                                                }}>{number[1]} months</p>

                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts1}</span>
                                                                </p>
                                                            </div>


                                                            <div className={"installments no-border"}>
                                                                <p className={"installment-title"}>{payment.od2_t}</p>
                                                                <p className={"installment-mode"}>{payment.od2_d}</p>
                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts2}</span>
                                                                </p>


                                                            </div>

                                                        </div>


                                                        <div className={"job-offer"}>
                                                            <div>
                                                                <button
                                                                    className={values.pricingPlan === payment.id ? "apply-enroll-btn selected" : "apply-enroll-btn"}
                                                                    onClick={() => changeTogglePricing(payment.id)}
                                                                >

                                                                    {values.pricingPlan === payment.id ? (
                                                                        <>Selected</>
                                                                    ) : (
                                                                        <>Select</>
                                                                    )}

                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                )
                                            }

                                            return null;
                                        })}


                                        {singleCourse && singleCourse.ci_id_p && singleCourse.ci_id_p.map((payment) => {

                                            if (payment.fmt === true && payment.ot === 'Budget Plan') {
                                                const number = payment.od1_p.split(",");
                                                return (
                                                    <Grid item xs={12} sm={5.8} md={5.8}
                                                          sx={{
                                                              padding: "10px 1px 18px 1px",
                                                              boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                          }}>

                                                        <div style={{padding: "0  0  0 20px",}}>
                                                            <div className={"option-icon-box"}>
                                                                <p className={"option-number budget"}>{payment.ot}</p>
                                                                <p className={"option-p"}>{payment.fmt === true ? "Online" : "On Campus"}</p>

                                                            </div>
                                                            <div className={"installments"}>
                                                                <p className={"installment-title"}>{payment.od1_t}</p>
                                                                <p className={"installment-mode"}>{payment.od1_d}</p>

                                                                <p style={{
                                                                    fontSize: "18px",
                                                                    fontWeight: "500",
                                                                    marginBottom: "-5px"
                                                                }}>${number[0]}
                                                                    <span
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400"
                                                                        }}> per month</span>
                                                                </p>
                                                                <p style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "400"
                                                                }}>{number[1]} months</p>

                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts1}</span>
                                                                </p>
                                                            </div>


                                                            <div className={"installments no-border"}>
                                                                <p className={"installment-title"}>{payment.od2_t}</p>
                                                                <p className={"installment-mode"}>{payment.od2_d}</p>
                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts2}</span>
                                                                </p>


                                                            </div>

                                                        </div>


                                                        <div className={"job-offer"}>
                                                            <div>
                                                                <button
                                                                    className={values.pricingPlan === payment.id ? "apply-enroll-btn selected" : "apply-enroll-btn"}
                                                                    onClick={() => changeTogglePricing(payment.id)}
                                                                >

                                                                    {values.pricingPlan === payment.id ? (
                                                                        <>Selected</>
                                                                    ) : (
                                                                        <>Select</>
                                                                    )}

                                                                </button>
                                                            </div>
                                                        </div>

                                                    </Grid>
                                                )
                                            }

                                            return null;
                                        })}

                                    </Grid>

                                ) : (
                                    <Grid container justifyContent={"space-between"}>
                                        {singleCourse && singleCourse.ci_id_p && singleCourse.ci_id_p.map((payment) => {

                                            if (payment.fmt === false && payment.ot === 'Standard Plan') {
                                                const number = payment.od1_p.split(",");
                                                return (
                                                    <Grid item xs={12} sm={5.8} md={5.8}
                                                          sx={{
                                                              padding: "10px 1px 18px 1px",
                                                              boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                          }}>

                                                        <div style={{padding: "0  0  0 20px",}}>
                                                            <div className={"option-icon-box"}>
                                                                <p className={"option-number"}>{payment.ot}</p>
                                                                <p className={"option-p"}>{payment.fmt === true ? "Online" : "On Campus"}</p>

                                                            </div>
                                                            <div className={"installments"}>
                                                                <p className={"installment-title"}>{payment.od1_t}</p>
                                                                <p className={"installment-mode"}>{payment.od1_d}</p>

                                                                <p style={{
                                                                    fontSize: "18px",
                                                                    fontWeight: "500",
                                                                    marginBottom: "-5px"
                                                                }}>${number[0]}
                                                                    <span
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400"
                                                                        }}> per month</span>
                                                                </p>
                                                                <p style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "400"
                                                                }}>{number[1]} months</p>

                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts1}</span>
                                                                </p>
                                                            </div>


                                                            <div className={"installments no-border"}>
                                                                <p className={"installment-title"}>{payment.od2_t}</p>
                                                                <p className={"installment-mode"}>{payment.od2_d}</p>
                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts2}</span>
                                                                </p>


                                                            </div>

                                                        </div>


                                                        <div className={"job-offer"}>
                                                            <div>
                                                                <button
                                                                    className={values.pricingPlan === payment.id ? "apply-enroll-btn selected" : "apply-enroll-btn"}
                                                                    onClick={() => changeTogglePricing(payment.id)}
                                                                >

                                                                    {values.pricingPlan === payment.id ? (
                                                                        <>Selected</>
                                                                    ) : (
                                                                        <>Select</>
                                                                    )}

                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                )
                                            }

                                            return null;
                                        })}


                                        {singleCourse && singleCourse.ci_id_p && singleCourse.ci_id_p.map((payment) => {

                                            if (payment.fmt === false && payment.ot === 'Budget Plan') {
                                                const number = payment.od1_p.split(",");
                                                return (
                                                    <Grid item xs={12} sm={5.8} md={5.8}
                                                          sx={{
                                                              padding: "10px 1px 18px 1px",
                                                              boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                          }}>

                                                        <div style={{padding: "0  0  0 20px",}}>
                                                            <div className={"option-icon-box"}>
                                                                <p className={"option-number budget"}>{payment.ot}</p>
                                                                <p className={"option-p"}>{payment.fmt === true ? "Online" : "On Campus"}</p>

                                                            </div>
                                                            <div className={"installments"}>
                                                                <p className={"installment-title"}>{payment.od1_t}</p>
                                                                <p className={"installment-mode"}>{payment.od1_d}</p>

                                                                <p style={{
                                                                    fontSize: "18px",
                                                                    fontWeight: "500",
                                                                    marginBottom: "-5px"
                                                                }}>${number[0]}
                                                                    <span
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400"
                                                                        }}> per month</span>
                                                                </p>
                                                                <p style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "400"
                                                                }}>{number[1]} months</p>

                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts1}</span>
                                                                </p>
                                                            </div>


                                                            <div className={"installments no-border"}>
                                                                <p className={"installment-title"}>{payment.od2_t}</p>
                                                                <p className={"installment-mode"}>{payment.od2_d}</p>
                                                                <p style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    marginTop: "10px"
                                                                }}>Total:
                                                                    <span style={{
                                                                        fontSize: "18px",
                                                                        color: "#00ADEF",
                                                                        fontWeight: "500"
                                                                    }}>${payment.ts2}</span>
                                                                </p>


                                                            </div>

                                                        </div>


                                                        <div className={"job-offer"}>
                                                            <div>
                                                                <button
                                                                    className={values.pricingPlan === payment.id ? "apply-enroll-btn selected" : "apply-enroll-btn"}
                                                                    onClick={() => changeTogglePricing(payment.id)}
                                                                >

                                                                    {values.pricingPlan === payment.id ? (
                                                                        <>Selected</>
                                                                    ) : (
                                                                        <>Select</>
                                                                    )}

                                                                </button>
                                                            </div>
                                                        </div>

                                                    </Grid>
                                                )
                                            }

                                            return null;
                                        })}

                                    </Grid>
                                )}



                                {recaptchaStatus === true && (
                                    <div style={{display: "flex", justifyContent: "center", margin: "10px"}}>

                                        <ReCAPTCHA
                                            sitekey="6LdNQ-kkAAAAAKAs8Nnww3tNiCMUV1yQcho2yj0b"
                                            ref={recaptchaRef}
                                            size="normal"
                                            onChange={onChangeRecaptcha}
                                            verifyCallback={verifyCallback}
                                        />
                                    </div>
                                )}

                                {submitError && (
                                    <p className={'step-four-error'}>An error has occurred </p>
                                )}
                                {errorVerify && (
                                    <p className={'step-four-error'}>An error has occurred with verification </p>
                                )}

                                <Grid item container alignSelf={'flex-end'} justifyContent={"center"}
                                      sx={{marginTop: "30px"}}>
                                    <button type="button" className={"nav-btns"}
                                            onClick={() => props.onBack(values)}> &lt; Back
                                    </button>
                                    <div style={{display: 'flex', alignContent: "center"}}>
                                        {/*    <button type="submit" className={"nav-btns"}>Next:</button>*/}
                                        <button className={"next-btn-title"} type='submit'>

                                 <span className={"next-btn-span"}><KeyboardDoubleArrowRightIcon
                                     sx={{color: "#00ADEF"}}/></span>
                                            <span className={"next-btn-p"}> Submit</span>
                                        </button>
                                    </div>
                                </Grid>

                            </Grid>
                        </Container>
                    </form>
                )}
            </Formik>
        )

    }


    const StepFive = () => {
        return (
            <div className={"confirmation"}>

                <div className={"confirmation-bg"}>

                    <div className={"confirmation-gradient"}>
                    </div>

                    <div className={"confirmation-info"}>
                        <h1 className={"confirmation-title"}>Thank you!</h1>
                        <p>A confirmation has been sent to your e-mail.</p>
                        <p>Our team will process your application and you will receive a link to sign your
                            enrollment agreement.</p>
                        <p>If you have any questions, don’t hesitate to reach out.</p>
                    </div>


                </div>
                <div className={"confirmation-icons"}>
                    <div>
                        <p>Be sure to follow us!</p>
                    </div>
                    <div className={"confirmation-icons-a"}>
                        <a href={"https://www.facebook.com/codefish.camp"} target="_blank" rel="noopener noreferrer">
                            <img src={facebook} alt="facebook"/>
                        </a>
                        <a href={"https://twitter.com/CodeFish_camp"} target="_blank" rel="noopener noreferrer">
                            <img src={twitter} alt="twitter"/>
                        </a>
                        <a href={"https://www.instagram.com/codefish_camp/"} target="_blank" rel="noopener noreferrer">
                            <img src={instagram} alt="instagram"/>
                        </a>
                        <a href={"https://www.linkedin.com/company/codefishllc/"} target="_blank"
                           rel="noopener noreferrer">
                            <img src={linkedin} alt="linkedin"/>
                        </a>
                        <a href={"https://t.me/codefish_academy"} target="_blank" rel="noopener noreferrer">
                            <img src={telegram} alt="telegram"/>
                        </a>
                    </div>


                </div>

            </div>
        )

    }

    const steps = [
        <StepOne onNext={handleNext} data={data}/>,
        <StepTwo onNext={handleNext} data={data} onBack={handlePrev}/>,
        <StepThree onNext={handleNext} data={data} onBack={handlePrev}/>,
        <StepFour onNext={handleNext} data={data} onBack={handlePrev}/>,
        <StepFive/>
    ]


    return (
        <Container maxWidth={'lg'}>

            {currentStep === steps.length - 1 ? (
                <>
                </>
            ) : (
                <>
                    <h3 className={"apply-title"}>
                        <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                        CodeFish Bootcamp Enrollment Application
                    </h3>

                    <p className="apply-p">
                        Ready to kickstart your IT career? We’re excited you want to join a CodeFish bootcamp! If you
                        have any
                        questions while filling out this application, don’t hesitate to contact us.
                    </p>
                </>

            )}

            <div style={{minHeight: "90vh", marginBottom: "20px"}}>
                {steps[currentStep]}
            </div>

        </Container>
    );
}

