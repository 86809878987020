import React, {useEffect, useState} from 'react';
import './styles.css';
import {Container} from "@mui/material";
import axiosApi from "../../axiosApi";
import DOMPurify from "dompurify";
import {mediaUrl} from "../../config";


const MainBlock = () => {

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/main_page/intro/');
                setData(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, []);



    function bgmove() {
        let elementTop = document.getElementById('main-bg').getBoundingClientRect().top;
        // $('.js_img_cont').css('transform', 'translate(0,-' + transformSpeed + 'px)')
        let transformSpeed = elementTop / 3.8;
        document.getElementById('new-bg').style.setProperty('transform', 'translate(0,' + transformSpeed + 'px)');
    }

    window.addEventListener("scroll", bgmove);

    return (
        <>
            {data.length > 0 && (
                <div className={"main-block"} id={'main-bg'}>
                    <div className={"new-bg"} id={"new-bg"}></div>
                    <div className={"new-bg-gradient"}></div>
                    <Container maxWidth={"lg"}>
                        <div className={"inner-block"}>
                            <div className={'text-block'}>

                                <h1 className={"main-block-main-title"}>
                                    {data[0].t}
                                    {/*Learn.Code.Grow.Earn.*/}
                                </h1>

                                <p className={'sub-title'}>{data[0].st}</p>

                                <div
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data[0].d)}}
                                    className={'main-block-p'}
                                >

                                </div>
                            </div>
                            <div className={"picture-block"}>
                                <div className={"picture-box"}>
                                    <img src={mediaUrl+data[0].img} alt="students" className={"pic"}/>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
        </>

    );
};

export default MainBlock;