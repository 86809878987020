import React, {useEffect, useState} from 'react';
import './styles.css';
import {Container, Grid} from "@mui/material";
import Player from "../../components/Player/Player";
import arrowBig from '../../assets/images/arrow_big.png';
import time from '../../assets/images/Vector.svg';
import calendar from '../../assets/images/Vector (1).svg';
import dollar from '../../assets/images/Icon.svg';
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import arrowTitle from "../../assets/images/arrow-logo.svg";
import arrow from '../../assets/images/arrow.png'
import {contacts, faq} from "../../paths";
import announce from '../../assets/images/Announce.svg'
import {useNavigate, useParams} from "react-router-dom";
import axiosApi from "../../axiosApi";
import {mediaUrl} from "../../config";
import DOMPurify from "dompurify";
import ModalComponentApply from "../../components/ModalComponentApply/ModalComponentApply";
import ReactGA from 'react-ga';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    borderBottom: "none"

}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgb(255, 255, 255)'
            : 'rgb(255, 255, 255)',
    flexDirection: 'row',
    alignItems: "center",
    padding: "10px 0",
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));

// const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
//     padding: theme.spacing(2),
//     border: "none"
// }));


const Programs = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState('');
    const [toggle, setToggle] = useState(1);
    const [applyModalShow, setApplyModalShow] = useState(false);


    ReactGA.initialize('G-BRZWJF3QP0');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/programs/programs/' + id + '/');
                setData(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, [id]);


    function bgmove() {
        let elementTop = document.getElementById('program-id').getBoundingClientRect().top;
        // $('.js_img_cont').css('transform', 'translate(0,-' + transformSpeed + 'px)')
        let transformSpeed = elementTop / 3.8;
        document.getElementById('program-bg').style.setProperty('transform', 'translate(0,' + transformSpeed + 'px)');


        let elementTop1 = document.getElementById('logo-block-id').getBoundingClientRect().top;
        // $('.js_img_cont').css('transform', 'translate(0,-' + transformSpeed + 'px)')
        let transformSpeed1 = elementTop1 / 3.8;
        document.getElementById('logo-bg').style.setProperty('transform', 'translate(0,' + transformSpeed1 + 'px)');

    }

    window.addEventListener("scroll", bgmove);


    return (
        <>

            {data && (
                <div className={"programs"} id={"program-id"}>
                    <div className={"programs-bg"} id={"program-bg"} style={{
                        backgroundImage: `url(${mediaUrl + data.bg_img})`,
                    }}></div>
                    <Container maxWidth={"lg"} sx={{display: "flex", flexDirection: "column"}}>

                        <Grid container alignItems={"center"}>
                            <Grid item xs={12} sm={7}>
                                <div className={"program-text-wrapper"}>
                                    <h3 className={"program-main-title"}>{data.t1}</h3>
                                    <p className={"programs-p"}>{data.t2}</p>
                                    <div className={'program-description'}
                                         dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.d1)}}>
                                    </div>
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div className={"program-icon-wrappper"}>
                                    <img src={mediaUrl + data.img_mp} alt={"program title"} className={"program-icon"}/>
                                </div>
                            </Grid>
                        </Grid>


                        {/*<div className={"btn-ratings-parent"}>*/}
                        {/*    <button className={"free-btn"}>Free consultation</button>*/}
                        {/*    <div style={{display: "flex"}}>*/}
                        {/*        <AvatarGroup max={3}>*/}
                        {/*            <Avatar alt="Remy Sharp" src={avatar} sx={{width: 60, height: 60}}/>*/}
                        {/*            <Avatar alt="Remy Sharp" src={avatar2} sx={{width: 60, height: 60}}/>*/}
                        {/*            <Avatar alt="Remy Sharp" src={avatar3} sx={{width: 60, height: 60}}/>*/}
                        {/*        </AvatarGroup>*/}
                        {/*        <div style={{marginLeft: "10px"}}>*/}
                        {/*            <Rating name="read-only"*/}
                        {/*                    value={4.5}*/}
                        {/*                    readOnly*/}
                        {/*                    precision={0.5}*/}
                        {/*                    getLabelText={(starValue) => {*/}
                        {/*                        if (starValue === value) {*/}
                        {/*                            if (starValue - Math.floor(starValue) === 0.5) {*/}
                        {/*                                return 'Half Filled';*/}
                        {/*                            } else {*/}
                        {/*                                return 'Filled';*/}
                        {/*                            }*/}
                        {/*                        }*/}
                        {/*                        return 'Empty';*/}
                        {/*                    }}*/}
                        {/*                    sx={{*/}
                        {/*                        '&.MuiRating-root': {*/}
                        {/*                            color: "#FAD6A1"*/}
                        {/*                        }*/}
                        {/*                    }}*/}
                        {/*            />*/}
                        {/*            <p>(4,5 / 3000+ students)</p>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}

                        {/*</div>*/}
                    </Container>
                </div>
            )}


            <Container maxWidth={"lg"}>
                <div className={"overview-block"}>
                    <h4 className={"overview-block-title"}>
                        <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                        Course overview
                    </h4>
                    {data && (
                        <Grid container justifyContent={"space-between"}>
                            <Grid item xs={12} md={6.8}>
                                <div className={"overview-block-inner"}>

                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.d2)}}>
                                    </div>



                                        {data.d2_h1 && (
                                            <div className={'bulleted-text'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.d2_h1)}}>
                                            </div>
                                        )}

                                        {data.d2_h2 && (
                                            <div className={'bulleted-text'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.d2_h2)}}>
                                            </div>
                                        )}

                                </div>
                            </Grid>
                            <Grid item xs={12} md={4.8} alignSelf={"start"}>
                                <Player url={data.l}/>
                            </Grid>

                        </Grid>
                    )}
                </div>
            </Container>
            <div className={"logo-block"} id={'logo-block-id'}>
                <div className={"logo-bg"} id={"logo-bg"} style={{
                    backgroundImage: `url(${data.ci_id_j && mediaUrl+data.ci_id_j.bg_img})`,
                    backgroundSize: 'cover'}}></div>
                {/*<div className={"gradient-bg"}></div>*/}
                <Container maxWidth={"lg"}>

                    {data.ci_id_j && (
                        <Grid container flexDirection={{xs: "columnn", md: "row",}}>
                            <Grid item container xs={12} sm={12} md={7} alignContent={"center"}
                                  sx={{position: "relative"}}>
                                <h4 className={"info-session-title"}
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.ci_id_j.t1)}}></h4>
                                <img src={announce} alt="announce" className={"announce-icon"}/>
                            </Grid>
                            <Grid item container xs={12} sm={12} md={5} flexDirection={"column"}
                                  style={{textAlign: "left"}}>
                                <h4 className={"Sdet"}>{data.ci_id_j.d1}</h4>
                                <p style={{color: "white", marginTop: "-10px"}}><i>{data.ci_id_j.d2}</i></p>
                                <p style={{fontSize: "24px", color: "white"}}>{data.ci_id_j.d3}</p>
                                <div className={"info-session-inner"}>
                                    <img src={mediaUrl+data.ci_id_j.ic} alt="calendar"/>
                                    <a href={data.ci_id_j.l} target="_blank" rel="noopener noreferrer" className={"info-register"}> <span
                                        className={"plus"}> + </span> <span className={"p"}>{data.ci_id_j.btn}</span></a>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Container>

            </div>


            <Container maxWidth={"lg"}>
                <h3 className={"start-title"}>
                    <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                    Course Start Dates
                </h3>
                <div className={"start-block"}>
                    <div className={"start_pic_wrapper"}>
                        <img src={mediaUrl + data.st_img} alt="start"/>
                    </div>
                    <Grid container sx={{padding: "32px 0"}}>
                        <Grid item container md={1}
                              sx={{display: {xs: "none", md: "flex"}}} justifyContent={"center"} alignItems={"center"}>
                            <Grid item>
                                <img src={arrowBig} alt="arrow" style={{alignSelf: "center"}}/>
                            </Grid>
                        </Grid>

                        <Grid item container md={11}>
                            {data.ci_id_sd && data.ci_id_sd.map(start => (
                                <Grid item xs={6} sm={6} md={3}
                                      sx={{
                                          borderRight: {md: "1px solid lightgrey", sm: "none"},
                                          paddingLeft: "35px",
                                          marginBottom: "25px"
                                      }}>
                                    <h5 className={"month_title"}>{start.t}</h5>
                                    <p><img src={time} alt="time"/> {start.d}</p>
                                    <p><img src={calendar} alt="calendar"/> {start.m}</p>
                                    <p><img src={dollar} alt="dollar"/> {start.p}</p>

                                    <button
                                        onClick={() => setApplyModalShow(true)}
                                        className={"register-link"}
                                    >
                                        <span className={'register-plus'}>+</span>
                                        <span className={'register-btn-p'}>Register</span>
                                    </button>
                                </Grid>
                            ))}

                        </Grid>
                    </Grid>
                </div>
                {applyModalShow && (
                    <ModalComponentApply open={applyModalShow} onClose={()=>setApplyModalShow(false)}/>
                )}
            </Container>

            <div className={"curriculum-block"}>
                <Container maxWidth={"lg"}>
                    <h3 className={"start-title"}>
                        <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                        Course curriculum
                    </h3>
                    <div className={"curriculum-accordion-parent"}>

                        {data.ci_id_cc && (
                            <div className={"accordion-child-curriculum"}>
                                {data.ci_id_cc.map(cur => (
                                    <Accordion expanded={expanded === 'panel' + cur.id}
                                               onChange={handleChange('panel' + cur.id)}>
                                        <AccordionSummary
                                            aria-controls="panel1d-content" id="panel1d-header"
                                            expandIcon={expanded === 'panel' + cur.id
                                                ? <KeyboardArrowDownIcon sx={{fontSize: '16px', color: "#00ADEF"}}/>
                                                : <KeyboardArrowDownIcon sx={{fontSize: '16px', color: "#000000"}}/>}
                                            className={"accordion"}
                                        >
                                            {expanded === 'panel' + cur.id ? (
                                                <div className={"curriculum-logo"}>
                                                    <img src={mediaUrl + cur.img} alt="java"
                                                         style={{
                                                             width: "90px",
                                                             marginRight: "30px",
                                                             alignSelf: "center"
                                                         }}/>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <p className={expanded === 'panel' + cur.id ? "expanded" : "not-expanded"}>
                                                            {cur.t}

                                                        </p>
                                                        <p className={"weeks"}>{cur.dt}</p>
                                                        <p className={"body-copy-accordion"}>
                                                            {cur.d}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={"curriculum-logo"}>
                                                    <img src={mediaUrl + cur.img} alt="java"
                                                         style={{width: "45px", marginRight: "20px"}}/>

                                                    <p className={expanded === 'panel' + cur.id ? "expanded" : "not-expanded"}>
                                                        {cur.t}
                                                    </p>

                                                </div>
                                            )}
                                        </AccordionSummary>
                                        {/*<AccordionDetails>*/}
                                        {/*   */}
                                        {/*</AccordionDetails>*/}
                                    </Accordion>
                                ))}
                            </div>
                        )}
                    </div>
                </Container>
            </div>


            {data && (
                <div className={"pricing-block"}>
                    <Container maxWidth={"lg"}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item sm={12} md={3.8}>
                                <div className={"pricing-pic-wrapper"}
                                     style={{
                                         backgroundImage: `url(${mediaUrl+data.pr_img})`,
                                         backgroundSize: 'cover',
                                     }}
                                >
                                    {/*<img src={mediaUrl+data.pr_img} alt="pricing-pic"/>*/}
                                </div>
                            </Grid>
                            <Grid item sm={12} md={7.7}>

                                <Grid container flexDirection={'column'}>
                                    <div style={{marginBottom: "15px"}}>
                                        <Grid container justifyContent={"space-between"}>
                                            <h3 className={"pricing-block-title"}>
                                                <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                                                Enrollment
                                            </h3>
                                            {/*<div className='btns-parent-pricing'>*/}
                                            {/*    <button className="more-btn"> More</button>*/}
                                            {/*    <button className="icon-btn"><ArrowOutwardIcon*/}
                                            {/*        sx={{color: "#00ADEF", fontSize: "17px"}}/></button>*/}
                                            {/*</div>*/}
                                        </Grid>
                                        {data && data.ci_id_p && data.ci_id_p.length <=2 && data.ci_id_p[0].fmt === true ? (

                                            <Grid container sx={{margin: "10px 0 25px"}}
                                                  justifyContent={{xs: "center", sm: "space-between"}}>
                                                <Grid item xs={12}>
                                                    <button
                                                        className={toggle === 1 ? "pricing-toggle-btn online online-active " : "pricing-toggle-btn online"}
                                                        onClick={() => setToggle(1)}
                                                    >
                                                        <span>Online</span>
                                                    </button>
                                                </Grid>
                                            </Grid>

                                            ):(
                                            <Grid container sx={{margin: "10px 0 25px"}}
                                                  justifyContent={{xs: "center", sm: "space-between"}}>
                                                <Grid item xs={6} sm={6}>
                                                    <button
                                                        className={toggle === 0 ? "pricing-toggle-btn campus campus-active" : "pricing-toggle-btn campus"}
                                                        onClick={() => setToggle(0)}
                                                    >
                                                        <span>On campus</span>
                                                    </button>
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    <button
                                                        className={toggle === 1 ? "pricing-toggle-btn online online-active " : "pricing-toggle-btn online"}
                                                        onClick={() => setToggle(1)}
                                                    >
                                                        <span>Online</span>
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        )}


                                        {toggle === 1 ? (
                                            <Grid container justifyContent={"space-between"}>
                                                {data && data.ci_id_p && data.ci_id_p.map(price => {
                                                    const number = price.od1_p.split(",");
                                                    const durations = price.od1_dur.split(",");
                                                    if(price.fmt === true && price.ot === 'Standard Plan'){
                                                        return (
                                                            <Grid item sm={12} md={5.8}
                                                                  sx={{
                                                                      padding: "10px 1px 18px 1px",
                                                                      boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                                      backgroundImage: `url(${arrow})`,
                                                                      backgroundPosition: "12px 27px",
                                                                      backgroundRepeat: "no-repeat",
                                                                  }}>

                                                                <div style={{padding: "0  0  0 62px",}}>
                                                                    <div className={"option-icon-box"}>
                                                                        <p className={"option-number"}>{price.ot}</p>
                                                                        <p className={"option-p"}>{price.fmt === true ? "Online" : "On Campus"}</p>

                                                                    </div>
                                                                    <div className={"installments"}>
                                                                        <p className={"installment-title"}>{price.od1_t}</p>
                                                                        <p className={"installment-mode"}>{price.od1_d}</p>

                                                                        <p style={{
                                                                            fontSize: "18px",
                                                                            fontWeight: "500",
                                                                            marginBottom: "-5px"
                                                                        }}>${number[0]}
                                                                            <span style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "400"
                                                                            }}> {durations[0]}</span>
                                                                        </p>
                                                                        <p style={{fontSize: "14px", fontWeight: "400"}}>
                                                                            {number[1]}{durations[1]}</p>

                                                                        <p style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400",
                                                                            marginTop: "10px"
                                                                        }}>Total:
                                                                            <span style={{
                                                                                fontSize: "18px",
                                                                                color: "#00ADEF",
                                                                                fontWeight: "500"
                                                                            }}>${price.ts1}</span>
                                                                        </p>
                                                                    </div>


                                                                    <div className={"installments no-border"}>
                                                                        <p className={"installment-title"}>{price.od2_t}</p>
                                                                        <p className={"installment-mode"}>{price.od2_d}</p>
                                                                        <p style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400",
                                                                            marginTop: "10px"
                                                                        }}>Total:
                                                                            <span style={{
                                                                                fontSize: "18px",
                                                                                color: "#00ADEF",
                                                                                fontWeight: "500"
                                                                            }}>${price.ts2}</span>
                                                                        </p>

                                                                    </div>

                                                                </div>

                                                                <div className={"job-offer"}>
                                                                    <div>
                                                                        <button className={"enroll-btn"} onClick={()=>setApplyModalShow(true)}>Enroll</button>
                                                                    </div>

                                                                    {/*<p style={{*/}
                                                                    {/*    fontSize: "13px",*/}
                                                                    {/*    fontWeight: "400",*/}
                                                                    {/*    padding: "10px ",*/}
                                                                    {/*    textAlign: "left"*/}
                                                                    {/*}}>*/}

                                                                    {/*    For full details on payment, please see our <a*/}
                                                                    {/*    href={faq}>FAQ</a>.*/}
                                                                    {/*    If you have any questions. don't hesitate to <a*/}
                                                                    {/*    href={contacts}>contact*/}
                                                                    {/*    us</a>*/}
                                                                    {/*</p>*/}

                                                                    <div style={{
                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        padding: "10px ",
                                                                        textAlign: "left"
                                                                    }}
                                                                         dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(price.n)}}
                                                                    >

                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    }

                                                    return null;
                                                })}
                                                {data && data.ci_id_p && data.ci_id_p.map(price => {
                                                    const number = price.od1_p.split(",");
                                                    const durations = price.od1_dur.split(",");
                                                    if(price.fmt === true && price.ot === 'Budget Plan'){
                                                        return (
                                                            <Grid item sm={12} md={5.8}
                                                                  sx={{
                                                                      padding: "10px 1px 18px 1px",
                                                                      boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                                      backgroundImage: `url(${arrow})`,
                                                                      backgroundPosition: "12px 27px",
                                                                      backgroundRepeat: "no-repeat",
                                                                  }}>

                                                                <div style={{padding: "0  0  0 62px",}}>
                                                                    <div className={"option-icon-box"}>
                                                                        <p className={"option-number budget"}>{price.ot}</p>
                                                                        <p className={"option-p"}>{price.fmt === true ? "Online" : "On Campus"}</p>

                                                                    </div>
                                                                    <div className={"installments"}>
                                                                        <p className={"installment-title"}>{price.od1_t}</p>
                                                                        <p className={"installment-mode"}>{price.od1_d}</p>

                                                                        <p style={{
                                                                            fontSize: "18px",
                                                                            fontWeight: "500",
                                                                            marginBottom: "-5px"
                                                                        }}>${number[0]}
                                                                            <span style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "400"
                                                                            }}> {durations[0]}</span>
                                                                        </p>
                                                                        <p style={{fontSize: "14px", fontWeight: "400"}}>
                                                                            {number[1]}{durations[1]}</p>

                                                                        <p style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400",
                                                                            marginTop: "10px"
                                                                        }}>Total:
                                                                            <span style={{
                                                                                fontSize: "18px",
                                                                                color: "#00ADEF",
                                                                                fontWeight: "500"
                                                                            }}>${price.ts1}</span>
                                                                        </p>
                                                                    </div>


                                                                    <div className={"installments no-border"}>
                                                                        <p className={"installment-title"}>{price.od2_t}</p>
                                                                        <p className={"installment-mode"}>{price.od2_d}</p>
                                                                        <p style={{
                                                                            fontSize: "16px",
                                                                            fontWeight: "400",
                                                                            marginTop: "10px"
                                                                        }}>Total:
                                                                            <span style={{
                                                                                fontSize: "18px",
                                                                                color: "#00ADEF",
                                                                                fontWeight: "500"
                                                                            }}>${price.ts2}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>


                                                                <div className={"job-offer"}>
                                                                    <div>
                                                                        <button className={"enroll-btn"} onClick={()=>setApplyModalShow(true)}>Enroll</button>
                                                                    </div>

                                                                    {/*<p style={{*/}
                                                                    {/*    fontSize: "13px",*/}
                                                                    {/*    fontWeight: "400",*/}
                                                                    {/*    padding: "10px ",*/}
                                                                    {/*    textAlign: "left"*/}
                                                                    {/*}}>*/}

                                                                    {/*    For full details on payment, please see our <a*/}
                                                                    {/*    href={faq}>FAQ</a>.*/}
                                                                    {/*    If you have any questions. don't hesitate to <a*/}
                                                                    {/*    href={contacts}>contact*/}
                                                                    {/*    us</a>*/}
                                                                    {/*</p>*/}

                                                                    <div style={{
                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        padding: "10px ",
                                                                        textAlign: "left"
                                                                    }}
                                                                         dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(price.n)}}
                                                                    >

                                                                    </div>
                                                                </div>

                                                            </Grid>
                                                        )
                                                    }

                                                    return null;
                                                })}

                                            </Grid>

                                        ) : (
                                                <Grid container justifyContent={"space-between"}>
                                                    {data && data.ci_id_p && data.ci_id_p.map(price => {
                                                        const number = price.od1_p.split(",")
                                                        const durations = price.od1_dur.split(",");
                                                        if(price.fmt !== true && price.ot === 'Standard Plan'){
                                                            return (
                                                                <Grid item sm={12} md={5.8}
                                                                      sx={{
                                                                          padding: "10px 1px 18px 1px",
                                                                          boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                                          backgroundImage: `url(${arrow})`,
                                                                          backgroundPosition: "12px 27px",
                                                                          backgroundRepeat: "no-repeat",
                                                                      }}>

                                                                    <div style={{padding: "0  0  0 62px",}}>
                                                                        <div className={"option-icon-box"}>
                                                                            <p className={"option-number"}>{price.ot}</p>
                                                                            <p className={"option-p"}>{price.fmt === false ? "On Campus " : "Online"}</p>

                                                                        </div>
                                                                        <div className={"installments"}>
                                                                            <p className={"installment-title"}>{price.od1_t}</p>
                                                                            <p className={"installment-mode"}>{price.od1_d}</p>

                                                                            <p style={{
                                                                                fontSize: "18px",
                                                                                fontWeight: "500",
                                                                                marginBottom: "-5px"
                                                                            }}>${number[0]}
                                                                                <span style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: "400"
                                                                                }}> {durations[0]}</span>
                                                                            </p>
                                                                            <p style={{fontSize: "14px", fontWeight: "400"}}>
                                                                                {number[1]}{durations[1]}</p>

                                                                            <p style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "400",
                                                                                marginTop: "10px"
                                                                            }}>Total:
                                                                                <span style={{
                                                                                    fontSize: "18px",
                                                                                    color: "#00ADEF",
                                                                                    fontWeight: "500"
                                                                                }}>${price.ts1}</span>
                                                                            </p>
                                                                        </div>


                                                                        <div className={"installments no-border"}>
                                                                            <p className={"installment-title"}>{price.od2_t}</p>
                                                                            <p className={"installment-mode"}>{price.od2_d}</p>
                                                                            <p style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "400",
                                                                                marginTop: "10px"
                                                                            }}>Total:
                                                                                <span style={{
                                                                                    fontSize: "18px",
                                                                                    color: "#00ADEF",
                                                                                    fontWeight: "500"
                                                                                }}>${price.ts2}</span>
                                                                            </p>

                                                                        </div>

                                                                    </div>

                                                                    <div className={"job-offer"}>
                                                                        <div>
                                                                            <button className={"enroll-btn"} onClick={()=>setApplyModalShow(true)}>Enroll</button>
                                                                        </div>

                                                                        {/*<p style={{*/}
                                                                        {/*    fontSize: "13px",*/}
                                                                        {/*    fontWeight: "400",*/}
                                                                        {/*    padding: "10px ",*/}
                                                                        {/*    textAlign: "left"*/}
                                                                        {/*}}>*/}

                                                                        {/*    For full details on payment, please see our <a*/}
                                                                        {/*    href={faq}>FAQ</a>.*/}
                                                                        {/*    If you have any questions. don't hesitate to <a*/}
                                                                        {/*    href={contacts}>contact*/}
                                                                        {/*    us</a>*/}
                                                                        {/*</p>*/}

                                                                        <div style={{
                                                                            fontSize: "13px",
                                                                            fontWeight: "400",
                                                                            padding: "10px ",
                                                                            textAlign: "left"
                                                                        }}
                                                                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(price.n)}}
                                                                        >

                                                                        </div>

                                                                    </div>
                                                                </Grid>
                                                            )
                                                        }

                                                        return null;
                                                    })}

                                                    {data && data.ci_id_p && data.ci_id_p.map(price => {
                                                        const number = price.od1_p.split(",");
                                                        const durations = price.od1_dur.split(",");
                                                        if(price.fmt !== true && price.ot === 'Budget Plan'){
                                                            return (
                                                                <Grid item sm={12} md={5.8}
                                                                      sx={{
                                                                          padding: "10px 1px 18px 1px",
                                                                          boxShadow: "-4px -4px 44px rgba(0, 0, 0, 0.08)",
                                                                          backgroundImage: `url(${arrow})`,
                                                                          backgroundPosition: "12px 27px",
                                                                          backgroundRepeat: "no-repeat",
                                                                      }}>

                                                                    <div style={{padding: "0  0  0 62px",}}>
                                                                        <div className={"option-icon-box"}>
                                                                            <p className={"option-number budget"}>{price.ot}</p>
                                                                            <p className={"option-p"}>{price.fmt === false ? "On Campus " : "Online"}</p>

                                                                        </div>
                                                                        <div className={"installments"}>
                                                                            <p className={"installment-title"}>{price.od1_t}</p>
                                                                            <p className={"installment-mode"}>{price.od1_d}</p>

                                                                            <p style={{
                                                                                fontSize: "18px",
                                                                                fontWeight: "500",
                                                                                marginBottom: "-5px"
                                                                            }}>${number[0]}
                                                                                <span style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: "400"
                                                                                }}> {durations[0]}</span>
                                                                            </p>
                                                                            <p style={{fontSize: "14px", fontWeight: "400"}}>
                                                                                {number[1]}{durations[1]}</p>

                                                                            <p style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "400",
                                                                                marginTop: "10px"
                                                                            }}>Total:
                                                                                <span style={{
                                                                                    fontSize: "18px",
                                                                                    color: "#00ADEF",
                                                                                    fontWeight: "500"
                                                                                }}>${price.ts1}</span>
                                                                            </p>
                                                                        </div>


                                                                        <div className={"installments no-border"}>
                                                                            <p className={"installment-title"}>{price.od2_t}</p>
                                                                            <p className={"installment-mode"}>{price.od2_d}</p>
                                                                            <p style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "400",
                                                                                marginTop: "10px"
                                                                            }}>Total:
                                                                                <span style={{
                                                                                    fontSize: "18px",
                                                                                    color: "#00ADEF",
                                                                                    fontWeight: "500"
                                                                                }}>${price.ts2}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>


                                                                    <div className={"job-offer"}>
                                                                        <div>
                                                                            <button className={"enroll-btn"} onClick={()=>setApplyModalShow(true)}>Enroll</button>
                                                                        </div>

                                                                        {/*<p style={{*/}
                                                                        {/*    fontSize: "13px",*/}
                                                                        {/*    fontWeight: "400",*/}
                                                                        {/*    padding: "10px ",*/}
                                                                        {/*    textAlign: "left"*/}
                                                                        {/*}}>*/}

                                                                        {/*    For full details on payment, please see our <a*/}
                                                                        {/*    href={faq}>FAQ</a>.*/}
                                                                        {/*    If you have any questions. don't hesitate to <a*/}
                                                                        {/*    href={contacts}>contact*/}
                                                                        {/*    us</a>*/}
                                                                        {/*</p>*/}

                                                                        <div style={{
                                                                            fontSize: "13px",
                                                                            fontWeight: "400",
                                                                            padding: "10px ",
                                                                            textAlign: "left"
                                                                        }}
                                                                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(price.n)}}
                                                                        >

                                                                        </div>

                                                                    </div>

                                                                </Grid>
                                                            )
                                                        }

                                                        return null;
                                                    })}
                                                </Grid>
                                        )}

                                    </div>
                                    <div className={"free-tuition"}>
                                        <h3>How about <b> free tuition?</b></h3>
                                        <p className={"promotion-text"}>Unlimited Referrals Earn <b>$500 off</b> your
                                            principal payment for <b>every</b> referred student who joins a CodeFish
                                            bootcamp!</p>

                                        <div className={"promotion"}>
                                            <p> For full details on payment, please see our <a href={faq}>FAQ</a>.
                                                If you have any questions, don't hesitate to <a href={contacts}>contact
                                                    us</a></p>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>

                </div>
            )}

        </>

    );
};

export default Programs;