import React from 'react';
import {Container, Grid} from "@mui/material";
import './styles.css'
import arrowTitle from "../../assets/images/arrow-logo.svg";
import {useEffect, useState} from "react";
import axiosApi from "../../axiosApi";


const Courses = () => {

    const [dataCourses, setDataCourses] = useState([]);
    const [error, setError] = useState(null);

    useEffect(()=>{
        const fetchDataCourses = async () => {
            try {
                const result = await axiosApi.get('/about_us/courses/');
                setDataCourses(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchDataCourses();
    },[])


    return (
        <>{dataCourses.length > 0 && (
            <Container maxWidth={"lg"} sx={{marginBottom: "50px"}}>
                <div className={"courses-block"}>
                    <h3 className={"team-title"}>
                        <img src={arrowTitle} alt="arrow" style={{marginRight: "10px"}}/>
                        About our courses</h3>
                    <div>
                        <p className={"courses-text"}>Programming is not a zero-sum game. Teaching something to a fellow
                            programmer doesn't take it away from you. I'm happy to share what I can, because <b>
                                I'm in it for
                                the love of programming.
                            </b>
                            <p className={"courses-text-author"}>{dataCourses[0].n}</p>

                            <p className={"courses-text-subtext"}>{dataCourses[0].p}</p>
                        </p>
                    </div>
                </div>

                <Grid container>

                    <Grid item xs={12} md={4}>
                        <div className={"courses-box-container"}>
                            <div className={"courses-box1"}>
                                <span>{dataCourses[0].hid[0].id}</span>
                            </div>
                            <div className={"courses-box2"}>
                                <h3 className={"blue"}>{dataCourses[0].hid[0].t}</h3>
                                <p>{dataCourses[0].hid[1].d}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={"courses-box-container"}>
                            <div className={"courses-box1"}>
                                <span className={"different-number"}>{dataCourses[0].hid[1].id}</span>
                            </div>
                            <div className={"courses-box2"}>
                                <h3 className={"red"}>{dataCourses[0].hid[1].t}</h3>
                                <p>{dataCourses[0].hid[1].d}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={"courses-box-container"}>
                            <div className={"courses-box1"}>
                                <span className={"different-number2"}>{dataCourses[0].hid[2].id}</span>
                            </div>
                            <div className={"courses-box2"}>
                                <h3 className={"green"}>{dataCourses[0].hid[2].t}</h3>
                                <p>{dataCourses[0].hid[2].d}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </Container>
        )}
        </>
    );
};

export default Courses;