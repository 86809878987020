import React, {useEffect, useState} from 'react';
import './styles.css'
import axiosApi from "../../axiosApi";
import Preloader from "../Preloader/Preloader";
import {mediaUrl} from "../../config";
import DOMPurify from "dompurify";
import {Container} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';


const BlogIframe = () => {
    const [posts, setPosts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showToTopButton, setShowToTopButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const convertTime = (date) => {
        const dt = new Date(date);

        const formattedDate = dt.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        return formattedDate;
    }


    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/blog/blog/');
                setPosts(result.data);
                console.log(result.data)
                setLoading(false)
            } catch (err) {
                setLoading(false)
                console.log(err);
            }
        };
        fetchData();

        const handleScroll = () => {
            if (window.scrollY > 800) {
                setShowToTopButton(true);
            } else {
                setShowToTopButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);


    return (
        <div className="iframe-wrap">
            {loading ? (
                <Preloader/>
            ) : (
                <Container maxWidth={"md"}>
                    <div className="blog-wrapper">
                        {posts && posts.length > 0 &&
                            posts.map((blog) => (
                                <div key={blog.id} className="blog-content-wrapper">
                                    <div className="post-block">
                                        <h2 className="blog-main-title">{blog.t}</h2>
                                        <img src={mediaUrl + blog.img} alt="post_pic" className="post-pic"/>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(blog.d)}}
                                         className="post-content"></div>
                                    {blog.dt && (
                                        <div style={{display: "flex", justifyContent: "right", color: "grey"}}>
                                            <p>Date: {convertTime(blog.dt)}</p></div>
                                    )}
                                </div>
                            ))}

                        <button onClick={scrollToTop} className={`to-top-button`} style={{display: showToTopButton ? 'block':'none'}}>
                        <KeyboardDoubleArrowUpIcon sx={{fontSize:"50px", color:"#00ADEF"}}/>
                        </button>

                    </div>
                </Container>
            )}
        </div>
    );
};

export default BlogIframe;