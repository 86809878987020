import React from 'react';
import Apply from "../Apply/Apply";
import './styles.css'

const ApplyPage = () => {
    return (
        <div className="apply-container">
            <Apply/>
        </div>
    );
};

export default ApplyPage;