import * as React from 'react';
import {useEffect, useState} from 'react';
import {AppBar, Box, Button, Container, MenuItem, Toolbar} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../../assets/images/codefish-logo.svg";
import {about, apply, blog, contacts, faq, main} from "../../paths";
import LayersIcon from '@mui/icons-material/Layers';
import './styles.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import ModalComponentApply from "../ModalComponentApply/ModalComponentApply";
import axiosApi from "../../axiosApi";


const AppToolBar = () => {
    const navigate = useNavigate();
    let location = useLocation();
    let pathname = location.pathname;

    const pages = [
        // {name: 'Programs', route: programs},
        {name: 'FAQ', route: faq},
        {name: 'Contact Us', route: contacts},
        {name: 'Blog', route: blog},
    ];

    const [openMenu, setOpenMenu] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)
    const [applyModalShow, setApplyModalShow] = useState(false);

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/programs/dropdown/programs/');
                setData(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, []);


    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setMobileMenu(false);

    };


    document.addEventListener("click", function(event) {
        if (!document.getElementById("program-menu").contains(event.target)) {
           setOpenMenu(false)
        }
    });


    console.log(pathname)

    return (
        <AppBar position="static" elevation={15}
                style={{
                    backgroundColor: "white",
                    boxShadow: "0px 8px 9px -5px rgb(0 0 0 / 0%), 0px 15px 22px 2px rgb(0 0 0 / 5%), 0px 6px 28px 5px rgb(0 0 0 / 0%)"
                }}
        >
            <Container maxWidth={"lg"}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img style={{width: "200px", minHeight: "85px"}}
                             src={logo} alt="codefish-logo"/>
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}, position:"relative", zIndex:"77"}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{color: "#00ADEF", fontSize: "35px"}}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                                width:"100%",
                            }}
                        >

                            <MenuItem onClick={handleCloseNavMenu}>
                                <Typography
                                    textAlign="center"
                                    component={Link}
                                    to={about}
                                    sx={{
                                        textDecoration: 'none',
                                        color: '#81807F',
                                        fontFamily: 'Objektiv-Mk2 , sans-serif',
                                        fontWeight: "400",
                                        textTransform: "capitalize",
                                        fontSize: "16px",
                                        "&:hover": {
                                            color: "#00ADEF",
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                >
                                    About Us
                                </Typography>
                            </MenuItem>

                            <MenuItem onClick={() => setMobileMenu(!mobileMenu)}>
                                <Typography
                                    textAlign="center"
                                    // component={Link}
                                    // to={about}
                                    sx={{
                                        textDecoration: 'none',
                                        color: '#81807F',
                                        fontFamily: 'Objektiv-Mk2 , sans-serif',
                                        fontWeight: "400",
                                        textTransform: "capitalize",
                                        fontSize: "16px",
                                        "&:hover": {
                                            color: "#00ADEF",
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                >
                                    Programs
                                </Typography>

                                {mobileMenu && (
                                    <ul className="drop-mobile-menu"

                                    >
                                        {/*<li>Alumni SDET Refresher*/}
                                        {/*</li>*/}

                                        {data.length > 0 && data.map(program=>(
                                            <li
                                                onClick={() => setMobileMenu(false)}
                                            ><Link to={"/programs/"+program.id}> {program.t1}</Link>
                                            </li>
                                        ))}

                                    </ul>
                                )}
                            </MenuItem>

                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                        component={Link}
                                        to={page.route}
                                        sx={{
                                            textDecoration: 'none',
                                            color: '#81807F',
                                            fontFamily: 'Objektiv-Mk2 , sans-serif',
                                            fontWeight: "400",
                                            textTransform: "capitalize",
                                            fontSize: "16px",
                                            "&:hover": {
                                                color: "#00ADEF",
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                    >
                                        {page.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>

                    </Box>


                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img style={{width: "140px", height: "auto"}}
                             src={logo} alt="codefish-logo"/>
                    </Typography>
                    <Box sx={{
                        flexGrow: 1,
                        display: {xs: 'none', md: 'flex'},
                        justifyContent: "center",
                    }}>

                        <Button
                            key={'about'}
                            onClick={() => navigate(about)}
                            sx={{
                                my: 2,
                                display: 'block',
                                color: pathname === about ? "#00ADEF" : '#81807F',
                                fontFamily: 'Objektiv-Mk2, sans-serif',
                                fontWeight: "400",
                                fontSize: "18px",
                                textTransform: "capitalize",
                                "&:hover": {
                                    color: "#00ADEF",
                                    backgroundColor: "transparent"
                                },
                                // "&.MuiButton-root":{
                                //     fontFamily: 'Solano Gothic Pro',
                                // }
                                // borderBottom: page.route === pathname ? '2px solid white' : 'none'
                            }}
                        >
                            About us
                        </Button>

                        <div className={'program-menu'} id={'program-menu'}>
                            <Button
                                key={'program'}

                                onClick={() => setOpenMenu(!openMenu)}
                                sx={{
                                    my: 2,
                                    display: 'block',
                                    color: pathname.indexOf('programs') !== -1 ? "#00ADEF" : '#81807F',
                                    fontFamily: 'Objektiv-Mk2, sans-serif',
                                    fontWeight: "400",
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                        color: "#00ADEF",
                                        backgroundColor: "transparent"
                                    },
                                    // "&.MuiButton-root":{
                                    //     fontFamily: 'Solano Gothic Pro',
                                    // }
                                    // borderBottom: page.route === pathname ? '2px solid white' : 'none'
                                }}
                            >
                                Programs
                            </Button>
                            {openMenu && (
                                <ul className="drop-menu"
                                >
                                    {data.length > 0 && data.map(program=>(
                                        <li onClick={() => setOpenMenu(false)}><Link to={"/programs/"+program.id}> {program.t1}</Link>
                                        </li>
                                    ))}

                                </ul>
                            )}

                        </div>


                        {pages.map(page => (
                            <Button
                                key={page.name}
                                onClick={ () => {
                                    navigate(page.route)
                              setOpenMenu(!openMenu)}}
                                sx={{
                                    my: 2,
                                    display: 'block',
                                    color: page.route === pathname && page.route !== main ? "#00ADEF" : '#81807F',
                                    fontFamily: 'Objektiv-Mk2, sans-serif',
                                    fontWeight: "400",
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                        color: "#00ADEF",
                                        backgroundColor: "transparent"
                                    },
                                    // "&.MuiButton-root":{
                                    //     fontFamily: 'Solano Gothic Pro',
                                    // }
                                    // borderBottom: page.route === pathname ? '2px solid white' : 'none'
                                }}
                            >
                                {page.name}
                            </Button>

                        ))}


                    </Box>


                    <div className={"apply-btn-parent"}>
                        <button className={"apply-btn"} onClick={() => setApplyModalShow(true)}>
                            <span><LayersIcon sx={{fontSize: "25px"}}/></span>
                            <p>Apply now</p>
                        </button>
                    </div>


                    {applyModalShow && (
                        <ModalComponentApply open={applyModalShow} onClose={()=>setApplyModalShow(false)}/>
                    )}


                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default AppToolBar;