import React, {useEffect, useState} from 'react';
import './styles.css'
import {Container, Grid,} from "@mui/material";
import arrow from "../../assets/images/arrow-svg.svg";
import axiosApi from "../../axiosApi";
import ModalComponent from "../ModalComponent/ModalComponent";
import {mediaUrl} from "../../config";

const BootcampBlock = () => {
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [selectedObj, setSelectedObj] = useState(null)
    const [showModal, setShowModal] = useState(false)

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/main_page/explore/');
                setData(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();
    },[])


    const onBtnClick = (obj)=>{
        setSelectedObj(obj);
        setShowModal(true)
    }


    return (
        <div className="bootcamp-block">
            <Container maxWidth={"lg"}>
                <h3><img src={arrow} alt="arrow" style={{marginRight: "10px"}}/> Expect More From Your Bootcamp</h3>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    {data.length > 0 && data.map(obj=>(
                        <Grid item xs={12} sm={6} md={6} lg={4} key={obj.id}>
                            <div className={"work-box"} onClick={()=>onBtnClick(obj)}>
                                <img src={mediaUrl+obj.ic} style={{paddingRight: "8px", width:"24px", height:"auto"}}/>
                                <p>{obj.t}</p>
                            </div>
                        </Grid>

                    ))}

                </Grid>

                {selectedObj && showModal && (
                    <ModalComponent open={showModal} onClose={()=>setShowModal(false)} url={selectedObj.vd} obj={selectedObj}/>
                )}

            </Container>
        </div>
    );
};

export default BootcampBlock;