import React from 'react';
import './styles.css';
import {Container, Grid} from "@mui/material";
import {ImLinkedin2} from "react-icons/im";
import {FaFacebookF, FaTelegramPlane} from "react-icons/fa";
import {BsInstagram, BsTwitter} from "react-icons/bs";


const Footer = () => {
    // const navigate = useNavigate()
    // const pages = [
    //     {name: 'About us', route: about},
    //     {name: 'Programs', route: programs},
    //     {name: 'FAQ', route: faq},
    //     {name: 'Contact Us', route: contacts},
    // ];

    return (
        <div>
            <div style={{boxShadow: "8px 8px 35px 6px rgba(161,149,149,0.75)"}}></div>
            <div className={"footer"}>
                <Container maxWidth={"lg"}>
                    <Grid container justifyContent={"space-between"} sx={{padding:"27px 0"}} >
                        {/*<Grid item>*/}
                        {/*    <Box sx={{*/}
                        {/*        display: {xs: 'none', md: 'flex', alignItems:"center"},*/}
                        {/*    }}>*/}
                        {/*        {pages.map(page => (*/}
                        {/*            <Button*/}
                        {/*                key={page.name}*/}
                        {/*                onClick={() => navigate(page.route)}*/}
                        {/*                sx={{*/}
                        {/*                    display: 'block',*/}
                        {/*                    color: '#6D737A',*/}
                        {/*                    fontSize:"16px",*/}
                        {/*                    marginRight:"20px",*/}
                        {/*                    fontFamily: 'Objektiv-Mk2, sans-serif',*/}
                        {/*                    fontWeight:"400",*/}
                        {/*                    textTransform: "capitalize",*/}
                        {/*                    "&:hover":{*/}
                        {/*                        color:"#00ADEF",*/}
                        {/*                        backgroundColor:"transparent"*/}
                        {/*                    }*/}
                        {/*                    // borderBottom: page.route === pathname ? '2px solid white' : 'none'*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                {page.name}*/}
                        {/*            </Button>*/}
                        {/*        ))}*/}
                        {/*    </Box>*/}
                        {/*</Grid>*/}
                        <Grid item sx={{marginBottom:"10px"}}>
                            <p>2200 E. Devon Ave. Suite 385</p>
                            <p>Des Plaines, IL 60018</p>
                            <p>1-224-386-1699</p>

                            <p>CodeFish, LLC | © 2023 | All Rights Reserved</p>
                        </Grid>
                        <Grid item>
                            <div className={"social-icons-btns-footer"}>

                                <a href={"https://www.facebook.com/codefish.camp"} target="_blank" rel="noopener noreferrer">
                                    <FaFacebookF/>
                                </a>
                                <a href={"https://twitter.com/CodeFish_camp"} target="_blank" rel="noopener noreferrer">
                                    <BsTwitter/>
                                </a>
                                <a href={"https://www.instagram.com/codefish_camp/"} target="_blank" rel="noopener noreferrer">
                                    <BsInstagram/>
                                </a>
                                <a href={"https://www.linkedin.com/company/codefishllc/"} target="_blank" rel="noopener noreferrer">
                                    <ImLinkedin2/>
                                </a>
                                <a href={"https://t.me/codefish_academy"} target="_blank" rel="noopener noreferrer">
                                    <FaTelegramPlane/>
                                </a>

                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

export default Footer;