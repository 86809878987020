import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import CustomNavigate from "./components/CustomNavigate/CustomNavigate";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const app = (
        <BrowserRouter>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover={false}/>
                    <App/>
                    <CustomNavigate/>
        </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));