import React, {useState} from 'react';
import {Container, Grid} from "@mui/material";
import missionPic from '../../assets/images/mission-pic.jpg';
import './styles.css';
import TeamBlock from "../../components/TeamBlock/TeamBlock";
import Campus from "../../components/Campus/Campus";
import Courses from "../../components/Courses/Courses";
import arrow from "../../assets/images/arrow-logo.svg";
import playButton from '../../assets/images/Video Play Button.svg'
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import {useEffect} from "react";
import axiosApi from "../../axiosApi";
import {apiURL, mediaUrl} from "../../config";
import DOMPurify from "dompurify";
import ReactGA from 'react-ga';

const About = () => {
    const[showPlayer, setShowPlayer] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    // console.log(data)
    ReactGA.initialize('G-BRZWJF3QP0');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axiosApi.get('/about_us/header/');
                setData(result.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchData();

    }, []);




    return (
        <>
            {data.length > 0 && (
                <Container maxWidth={"lg"} sx={{marginTop: "62px"}}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <h3 className={"mission-title"}><img src={arrow} alt="arrow"
                                                                 style={{marginRight: "10px"}}/>
                                {data[0].t}
                            </h3>

                            <div className={"mission-text"}
                                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data[0].d)}}>
                            </div>

                            {/*<p className={"mission-text"}><b>"In the waves of change, we find our direction."</b></p>*/}
                            {/*<p className={"mission-text"}>CodeFish was founded to empower people through the gain of*/}
                            {/*    knowledge to wholly improve their lives - both in their careers, and to the betterment of*/}
                            {/*    their respective communities.</p>*/}
                            {/*<p className={"mission-text"}>CodeFish delivers both online and in-person IT bootcamps with a*/}
                            {/*    variety of subjects. With each offering, we produce well-rounded, certified entry-level IT*/}
                            {/*    professionals. Codefish graduates are ready to be productive team members on day one</p>*/}
                            {/*<p className={"mission-text"}>By helping our dedicated enrollees enter the job market, Codefish*/}
                            {/*    aims to better our community - both locally in the northern suburbs of Chicago, and*/}
                            {/*    beyond.</p>*/}

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={"mission-pic-wrapper"}>
                                <div className={"mission-gradient"} onClick={()=>setShowPlayer(true)}>
                                    <img src={playButton} alt="play" className={"mission-video-play-btn"}/>
                                </div>
                                <img src={mediaUrl+data[0].pr_img} alt="mission" className={"mission-pic"}/>
                            </div>
                        </Grid>
                    </Grid>
                    <ModalComponent open={showPlayer} onClose={()=>setShowPlayer(false)} url={data[0].l}/>
                    <TeamBlock/>
                </Container>
            )}

            <Campus/>
            <Courses/>
        </>
    );
};

export default About;